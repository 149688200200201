input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.account-page {
  display: flex;
  align-items: center;
  flex-flow: column;
  height: 100vh;
  justify-content: center;

  h4 {
    font-family: Lora, sans-serif;
    margin: 15px 0 0;
    font-size: 22px;

    a {
      margin-top: -5px;
    }
  }

  .or {
    font-size: 21px;
    line-height: 24px;
    font-weight: 600;
    text-align: center;
    margin: 35px 0 30px;
    position: relative;

    &:before {
      content: '';
      display: inline-block;
      width: 120px;
      position: absolute;
      background: #000;
      height: 2px;
      left: 0;
      top: 50%;
      margin-top: -1px;
      transform: translateX(40%);
    }

    &:after {
      content: '';
      display: inline-block;
      width: 120px;
      position: absolute;
      background: #000;
      height: 2px;
      right: 0;
      top: 50%;
      margin-top: -1px;
      transform: translateX(-40%);
    }
  }

  .login-platform-btn {
    width: 100%;
    border-radius: 10px;
    padding: 13px 10px;
    margin: 0 0 20px;
    border: 0;
    font-size: 16px;

    img {
      margin: -1px 10px 0 0;
    }

    &:last-of-type {
      margin: 0;
    }

    &.login-with-google {
      background: #FFFFFF;
      box-shadow: 0px 0px 3px rgb(0 0 0 / 8%), 0px 2px 3px rgb(0 0 0 / 17%);
    }

    &.login-with-fb {
      background: #1877F2;
      color: #fff;
    }
  }

  .form-wrapper {
    width: 600px;
    max-width: 100%;
    text-align: left;
    margin: 30px auto;
    background: #FFFFFF;
    box-shadow: 0px 0px 5px rgb(0 0 0 / 25%);
    padding: 35px 50px;
  }

  button[type="submit"] {
    width: 100%;
  }

  .form-group {
    margin: 0 0 25px;

    label {
      font-family: Oswald, sans-serif;
      font-weight: 400;
      margin: 0 0 10px;
      text-transform: uppercase;
      font-size: 14px;
    }

    .form-control {
      box-shadow: 0px 0px 1px rgb(0 0 0 / 62%);
      background: #fff;
      padding: 12px 15px;
      border-radius: 8px;
      border: 1px solid #000;
      font-size: 14px;
      font-family: poppins, sans-serif;
      color: #000;

      &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        font-size: 14px;
        font-family: Lora, sans-serif;
      }

      &:-ms-input-placeholder { /* Internet Explorer 10-11 */
        font-size: 14px;
        font-family: Lora, sans-serif;
      }

      &::-ms-input-placeholder { /* Microsoft Edge */
        font-size: 14px;
        font-family: Lora, sans-serif;
      }
    }

  }

}
