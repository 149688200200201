// contact-us
.contact-us-page {

  .our-details {

    p {
      line-height: 20px;
      padding-right: 50px;
    }

    .ctas {
      margin: 50px 0;
      a {
        color: #000;
      }
    }

    .map {
      margin: 50px 0 0;
    }
  }

  .contact-form {
    background: #FFFFFF;
    box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 10%);
    padding: 50px;

    h4 {
      margin: 0 0 40px;
    }
  }
}

// board members
.members {
  .member {
    display: flex;
    align-items: center;
    margin: 50px 0;

    figure {
      width: 300px;
      height: 300px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .member-detail {
      width: calc(100% - 300px);
      padding: 0 150px 0 70px;

      .member-name {
        font-weight: 600;
        font-size: 48px;
        line-height: 72px;
        margin-bottom: 25px;
      }

      p {
        font-family: Lora, sans-serif;
      }
    }

    &:nth-of-type(2n) {
      figure {
        order: 2;
      }

      .member-detail {
        padding: 0 70px 0 150px;
      }
    }


  }
}

// privacy policy page
.policy-terms {
  font-family: Lora, sans-serif;

  ul {
    margin: 30px 0;
    list-style: disc;
    padding: 0 0 0 20px;
  }
}

// author-directory
.author-search-form {
  input {
    border: 0;
    width: 482px;
    height: 50px;
    background: #FFFFFF;
    box-shadow: 4px 4px 21px rgb(0 0 0 / 25%);
    border-radius: 56px;
    padding: 15px 25px;
    font-family: Lora, sans-serif;
    font-size: 15px;
  }
  button {
    border-radius: 25px;
    position: absolute;
    right: 4px;
    width: 116px;
    height: 42px;
    font-size: 12px;
    text-align: center;
    padding: 0;
    line-height: 42px;
    top: 4px;
  }


}
.directory-characters {

  .char {
    font-size: 70px;
    font-weight: 700;
    line-height: 90px;
    margin-right: 40px;
  }
}

// e-commerce thank you page
.after-checkout-page{

  h3 {
    margin: 0 0 20px;
  }

  p {
    padding: 10px 0;
    font-size: 16px;
  }
}


// Author page
.single-author {

  .about-author {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 50px 0 70px;
  }

  .author-image {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    border: 1px solid #eee;
    overflow: hidden;
    box-shadow: 0px 0px 8px #e9e9e9;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .author-details {
    width: calc(100% - 300px);
    padding-left: 150px;
  }

  .author-description {

    h2 {
      font-size: 48px;
      font-family: Poppins, sans-serif;
      font-weight: 700;
      margin: 0 0 30px;
    }

    p {
      font-size: 16px;
      font-family: Lora, sans-serif;
      line-height: 22px;
    }

    .descp {

      a {
        margin-top: -3px;
        color: #0d6efd;
        text-decoration: underline;
      }
    }
  }

  .author-social-links {
    padding: 50px 0 0;
    .social-link {
      width: 36px;
      height: 36px;
      background: #000;
      color: #fff;
      line-height: 34px;
      text-align: center;
      border-radius: 50%;
      font-size: 18px;
      margin: 0 15px 0 0;
    }
  }

  .articles-by-author {
    background: #F5F5F5;
    padding: 25px 0;

    .author-article {
      padding: 25px 0;
      display: flex;

      .author-article-details {
        width: calc(100% - 330px);
        padding-right: 100px;
      }

      .author-article-image {
        width: 330px;
        display: inline-block;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .article-title {
        font-size: 32px;
        font-weight: 700;
        margin: 5px 0 20px;
        display: block;
        line-height: 40px;
      }

      .article-description {
        font-family: Lora, sans-serif;
        font-size: 16px;
        line-height: 22px;
      }

      .simple-link {
        padding: 10px 0 0;
      }
    }
  }

}

.subscription-plans-page {
  margin: 80px 0 40px;

  .plan-cards {
    display: flex;
    justify-content: center;
  }

  .plan-card {
    width: 300px;
    max-width: 100%;
    border: 0;
    padding: 50px 12px;

    .btn {
      width: 100%;
      padding: 5px 10px;
      border-radius: 20px;
      margin: 30px 0 0;
      text-transform: initial;
    }

    &.favorite-plan {
      border: 2px solid #111111;
      border-radius: 25px;
      padding: 60px 12px;
    }

    .pricing {
      margin-bottom: 20px;

      h1 {
        font-size: 42px;
        line-height: 38px;
        display: inline-block;
        font-weight: 700;
        color: #231D4F;
      }

      span {
        display: block;
        font-size: 18px;
        color: #bdbcc3;
      }
    }

    .plan-description {
      color: #848199;
      padding: 10px 0 15px;
      line-height: 21px;
    }

    ul {
      li {
        font-size: 15px;
        color: #848199;
        padding: 10px 0;

        &:before {
          content: '\f00c';
          font-family: "Font Awesome 5 Free";
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background: $color__primary;
          display: inline-block;
          text-align: center;
          color: #fff;
          font-size: 12px;
          font-weight: 700;
          margin-right: 5px;
          line-height: 20px;
        }
      }
    }
  }
}
