footer {
  border-top: 1px solid #eee;
  background: #f9f9f9;
  margin-top: 25px;

  .link-card-title {
    font-weight: bold;
    color: #333 !important;
    margin-bottom: 15px;
  }

  .link-card {
    @media (max-width: 575.98px) {
      width: 100%;
      margin: 0 0 20px;
    }

    ul {

      li {
        padding: 5px 0;
        font-family: Poppins, sans-serif;
      }
    }
  }
}

.subscribe-section {

  p:not(.copyright_text) {
    font-size: 18px;
  }

  h4 {
    font-weight: 400;
    margin: 25px 0 50px;
    @media (max-width: 575.98px) {
      margin: 30px;
    }
  }

  .footer-links {
    padding: 50px 150px;
    @media (max-width: 575.98px) {
      flex-wrap: wrap;
      padding: 20px;

      li {
        width: 100%;
        padding: 5px 0;
      }
    }
  }


}
