@charset "UTF-8";
/* poppins-regular - latin_devanagari */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/Poppins/poppins-v15-latin_devanagari-regular.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../../fonts/Poppins/poppins-v15-latin_devanagari-regular.eot?#iefix") format("embedded-opentype"), url("../../fonts/Poppins/poppins-v15-latin_devanagari-regular.woff2") format("woff2"), url("../../fonts/Poppins/poppins-v15-latin_devanagari-regular.woff") format("woff"), url("../../fonts/Poppins/poppins-v15-latin_devanagari-regular.ttf") format("truetype"), url("../../fonts/Poppins/poppins-v15-latin_devanagari-regular.svg#Poppins") format("svg");
  /* Legacy iOS */
}
/* poppins-500 - latin_devanagari */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: url("../../fonts/Poppins/poppins-v15-latin_devanagari-500.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../../fonts/Poppins/poppins-v15-latin_devanagari-500.eot?#iefix") format("embedded-opentype"), url("../../fonts/Poppins/poppins-v15-latin_devanagari-500.woff2") format("woff2"), url("../../fonts/Poppins/poppins-v15-latin_devanagari-500.woff") format("woff"), url("../../fonts/Poppins/poppins-v15-latin_devanagari-500.ttf") format("truetype"), url("../../fonts/Poppins/poppins-v15-latin_devanagari-500.svg#Poppins") format("svg");
  /* Legacy iOS */
}
/* poppins-600 - latin_devanagari */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  src: url("../../fonts/Poppins/poppins-v15-latin_devanagari-600.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../../fonts/Poppins/poppins-v15-latin_devanagari-600.eot?#iefix") format("embedded-opentype"), url("../../fonts/Poppins/poppins-v15-latin_devanagari-600.woff2") format("woff2"), url("../../fonts/Poppins/poppins-v15-latin_devanagari-600.woff") format("woff"), url("../../fonts/Poppins/poppins-v15-latin_devanagari-600.ttf") format("truetype"), url("../../fonts/Poppins/poppins-v15-latin_devanagari-600.svg#Poppins") format("svg");
  /* Legacy iOS */
}
/* poppins-700 - latin_devanagari */
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  src: url("../../fonts/Poppins/poppins-v15-latin_devanagari-700.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../../fonts/Poppins/poppins-v15-latin_devanagari-700.eot?#iefix") format("embedded-opentype"), url("../../fonts/Poppins/poppins-v15-latin_devanagari-700.woff2") format("woff2"), url("../../fonts/Poppins/poppins-v15-latin_devanagari-700.woff") format("woff"), url("../../fonts/Poppins/poppins-v15-latin_devanagari-700.ttf") format("truetype"), url("../../fonts/Poppins/poppins-v15-latin_devanagari-700.svg#Poppins") format("svg");
  /* Legacy iOS */
}
/* oswald-500 - latin */
@font-face {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 500;
  src: url("../../fonts/Oswald/oswald-v39-latin-500.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../../fonts/Oswald/oswald-v39-latin-500.eot?#iefix") format("embedded-opentype"), url("../../fonts/Oswald/oswald-v39-latin-500.woff2") format("woff2"), url("../../fonts/Oswald/oswald-v39-latin-500.woff") format("woff"), url("../../fonts/Oswald/oswald-v39-latin-500.ttf") format("truetype"), url("../../fonts/Oswald/oswald-v39-latin-500.svg#Oswald") format("svg");
  /* Legacy iOS */
}
/* oswald-regular - latin */
@font-face {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/Oswald/oswald-v39-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../../fonts/Oswald/oswald-v39-latin-regular.eot?#iefix") format("embedded-opentype"), url("../../fonts/Oswald/oswald-v39-latin-regular.woff2") format("woff2"), url("../../fonts/Oswald/oswald-v39-latin-regular.woff") format("woff"), url("../../fonts/Oswald/oswald-v39-latin-regular.ttf") format("truetype"), url("../../fonts/Oswald/oswald-v39-latin-regular.svg#Oswald") format("svg");
  /* Legacy iOS */
}
/* oswald-700 - latin */
@font-face {
  font-family: "Oswald";
  font-style: normal;
  font-weight: 700;
  src: url("../../fonts/Oswald/oswald-v39-latin-700.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../../fonts/Oswald/oswald-v39-latin-700.eot?#iefix") format("embedded-opentype"), url("../../fonts/Oswald/oswald-v39-latin-700.woff2") format("woff2"), url("../../fonts/Oswald/oswald-v39-latin-700.woff") format("woff"), url("../../fonts/Oswald/oswald-v39-latin-700.ttf") format("truetype"), url("../../fonts/Oswald/oswald-v39-latin-700.svg#Oswald") format("svg");
  /* Legacy iOS */
}
/* lora-regular - latin */
@font-face {
  font-family: "Lora";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/Lora/lora-v17-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../../fonts/Lora/lora-v17-latin-regular.eot?#iefix") format("embedded-opentype"), url("../../fonts/Lora/lora-v17-latin-regular.woff2") format("woff2"), url("../../fonts/Lora/lora-v17-latin-regular.woff") format("woff"), url("../../fonts/Lora/lora-v17-latin-regular.ttf") format("truetype"), url("../../fonts/Lora/lora-v17-latin-regular.svg#Lora") format("svg");
  /* Legacy iOS */
}
/* lora-600 - latin */
@font-face {
  font-family: "Lora";
  font-style: normal;
  font-weight: 600;
  src: url("../../fonts/Lora/lora-v20-latin-600.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../../fonts/Lora/lora-v20-latin-600.eot?#iefix") format("embedded-opentype"), url("../../fonts/Lora/lora-v20-latin-600.woff2") format("woff2"), url("../../fonts/Lora/lora-v20-latin-600.woff") format("woff"), url("../../fonts/Lora/lora-v20-latin-600.ttf") format("truetype"), url("../../fonts/Lora/lora-v20-latin-600.svg#Lora") format("svg");
  /* Legacy iOS */
}
/* lora-500 - latin */
@font-face {
  font-family: "Lora";
  font-style: normal;
  font-weight: 500;
  src: url("../../fonts/Lora/lora-v20-latin-500.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../../fonts/Lora/lora-v20-latin-500.eot?#iefix") format("embedded-opentype"), url("../../fonts/Lora/lora-v20-latin-500.woff2") format("woff2"), url("../../fonts/Lora/lora-v20-latin-500.woff") format("woff"), url("../../fonts/Lora/lora-v20-latin-500.ttf") format("truetype"), url("../../fonts/Lora/lora-v20-latin-500.svg#Lora") format("svg");
  /* Legacy iOS */
}
/* pt-serif-regular - latin */
@font-face {
  font-family: "PT Serif";
  font-style: normal;
  font-weight: 400;
  src: url("../../fonts/Pt_serif/pt-serif-v12-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../../fonts/Pt_serif/pt-serif-v12-latin-regular.eot?#iefix") format("embedded-opentype"), url("../../fonts/Pt_serif/pt-serif-v12-latin-regular.woff2") format("woff2"), url("../../fonts/Pt_serif/pt-serif-v12-latin-regular.woff") format("woff"), url("../../fonts/Pt_serif/pt-serif-v12-latin-regular.ttf") format("truetype"), url("../../fonts/Pt_serif/pt-serif-v12-latin-regular.svg#PTSerif") format("svg");
  /* Legacy iOS */
}
/* pt-serif-700 - latin */
@font-face {
  font-family: "PT Serif";
  font-style: normal;
  font-weight: 700;
  src: url("../../fonts/Pt_serif/pt-serif-v12-latin-700.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../../fonts/Pt_serif/pt-serif-v12-latin-700.eot?#iefix") format("embedded-opentype"), url("../../fonts/Pt_serif/pt-serif-v12-latin-700.woff2") format("woff2"), url("../../fonts/Pt_serif/pt-serif-v12-latin-700.woff") format("woff"), url("../../fonts/Pt_serif/pt-serif-v12-latin-700.ttf") format("truetype"), url("../../fonts/Pt_serif/pt-serif-v12-latin-700.svg#PTSerif") format("svg");
  /* Legacy iOS */
}
* {
  box-sizing: border-box;
  background-color: transparent;
  margin: 0;
}

ol, ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after {
  content: "";
}

q:before, q:after {
  content: "";
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a,
div,
h1,
h2,
h3,
h4,
h5,
p,
span {
  text-shadow: none;
}

[type=button]:focus,
a:active,
a:focus,
a:visited,
button::-moz-focus-inner,
input[type=reset]::-moz-focus-inner,
input[type=button]::-moz-focus-inner,
input[type=submit]::-moz-focus-inner,
input[type=file] > input[type=button]::-moz-focus-inner,
select::-moz-focus-inner {
  outline: 0;
}

input,
.form-control,
.form-control:focus,
input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none;
  outline-width: 0;
  outline-color: transparent;
  box-shadow: none;
}

textarea {
  resize: none;
  overflow-x: hidden;
}

.btn,
.btn-group.open .dropdown-toggle,
.btn:active,
.btn:focus,
.btn:hover,
.btn:visited,
a,
a:active,
a:checked,
a:focus,
a:hover,
a:visited,
body,
button,
button:active,
button:hover,
button:visited,
div,
input,
input:active,
input:focus,
input:hover,
input:visited,
select,
select:active,
select:focus,
select:visited,
textarea,
textarea:active,
textarea:focus,
textarea:hover,
textarea:visited {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus,
button,
button:active,
button:checked,
button:focus,
button:hover,
button:visited {
  outline: 0;
  outline-offset: 0;
}

.bootstrap-select .dropdown-toggle:focus {
  outline: 0;
  outline-offset: 0;
}

.dropdown-menu > li > a:active,
.dropdown-menu > li > a:focus,
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:visited {
  outline: 0;
}

a:focus,
input:focus {
  border-color: transparent;
  outline: none;
}

body {
  font-family: "PT Serif", serif;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  line-height: 18px;
  background-color: #FFFFFF;
}

img {
  max-width: 100%;
}

figure, p {
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

.b0 {
  bottom: 0 !important;
}

.text-red {
  color: #E1002A !important;
}

.text-grey {
  color: #808080 !important;
}

.text-light-grey {
  color: #9b9b9b !important;
}

.text-white {
  color: #FFFFFF !important;
}

.text-light-white {
  color: #F5F5F5 !important;
}

.text-primary {
  color: #FED51D !important;
}

.bg-yellow {
  background-color: #FED51D !important;
}

.bg-black {
  background-color: #000000 !important;
}

.bg-white {
  background-color: #FFFFFF !important;
}

.font-poppins {
  font-family: Poppins, sans-serif !important;
}

.font-oswald {
  font-family: Oswald, sans-serif !important;
}

.font-lora {
  font-family: Lora, sans-serif !important;
}

.clearfix {
  clear: both;
}

.has-border-radius {
  border-radius: 12px;
}

.border-0 {
  border: 0;
}

.no-middle {
  vertical-align: unset;
}

.pagination {
  justify-content: center;
  margin: 40px 0 0;
}
.pagination .page-item.disabled {
  cursor: not-allowed;
}
.pagination .page-item .page-link {
  color: #fed51d;
}
.pagination .page-item.active .page-link {
  background-color: #000;
  border-color: #000;
}
.pagination .page-item:first-of-type .page-link, .pagination .page-item:last-of-type .page-link {
  color: #000;
  font-size: 22px;
}

a {
  text-decoration: none;
  color: #000000;
  display: inline-block;
}
a:hover {
  text-decoration: underline;
  color: #E1002A;
}

h4, .h4 {
  font-size: 24px;
  line-height: 32px;
}
@media (max-width: 575.98px) {
  h4, .h4 {
    font-size: 20px;
    line-height: 28px;
  }
}

h5 {
  font-size: 18px;
  line-height: 24px;
}

.spacing-around {
  padding-top: 50px;
  padding-bottom: 50px;
  font-size: inherit;
}
.spacing-around.small {
  padding-top: 25px;
  padding-bottom: 25px;
}
.spacing-around.large {
  padding-top: 100px;
  padding-bottom: 100px;
}

.section-title {
  padding: 20px 0;
  border: 1px solid #CDCDCD;
  border-width: 1px 0;
}
.section-title h2 {
  font-size: 48px;
  line-height: 48px;
  text-transform: uppercase;
  margin-bottom: 15px;
}
@media (max-width: 575.98px) {
  .section-title h2 {
    font-size: 28px;
    line-height: 28px;
  }
}

.owl-carousel .owl-dots {
  text-align: center;
}
.owl-carousel .owl-dots .owl-dot {
  margin: 0 2px;
}
.owl-carousel .owl-dots .owl-dot span {
  display: inline-block;
  background-color: #000000;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.owl-carousel .owl-dots .owl-dot.active span {
  background-color: #E1002A;
  width: 12px;
  height: 8px;
  border-radius: 5px;
}

.ad-space-full {
  padding: 20px 10px;
  margin: 0 0 20px;
  border: 1px solid #CDCDCD;
  border-width: 1px 0;
}

.pr-0 {
  padding-right: 0 !important;
}

.divider {
  border-top: 1px solid #CDCDCD;
  margin: 10px 0 30px;
}

.subscription-renew-alert {
  font-size: 14px;
  font-family: Lora, sans-serif;
  letter-spacing: 0.5px;
  margin: 10px 0;
  padding: 10px 25px;
}
.subscription-renew-alert .fas {
  font-size: 20px;
  margin: 0 5px 0 0;
}

.alert-link {
  vertical-align: baseline;
}

.sharethis-inline-share-buttons {
  z-index: 1 !important;
}

.btn {
  font-size: 18px;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  padding: 10px 38px;
  border-radius: 10px;
}
.btn.btn-large {
  padding: 12px 38px;
}
.btn.style-1 {
  background-color: #FED51D;
  color: #000000;
}
.btn.style-2 {
  background-color: #000000;
  color: #FED51D;
}
.btn.style-3 {
  background-color: #FFFFFF;
  color: #FED51D;
  border: 2px solid #FED51D;
}

button.is-icon {
  border: 0;
}

.read_more_link {
  font-family: Oswald, sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  text-transform: uppercase;
}

.simple-link {
  color: #0d6efd;
  text-decoration: underline;
}

header {
  position: relative;
}
header #slide-menu-button {
  font-size: 24px;
  width: 33px;
}
header .slidemenu {
  position: absolute;
  padding: 20px 0;
  background: #fff;
  width: 100%;
  box-shadow: 0px 4px 8px #ccc;
  top: -260%;
  transition: all 0.8s;
  z-index: 999999;
}
header .slidemenu.show {
  top: 60px;
}
header .slidemenu ul li {
  display: block;
  padding: 0;
}
header .slidemenu ul li.parent-link a {
  font-size: 18px;
  color: #E1002A;
  padding: 5px 0 15px;
}
header .slidemenu ul li a {
  font-size: 14px;
  padding: 5px 0;
}
header ul > li {
  display: inline-block;
}
header .top-bar {
  padding: 5px 0;
  position: relative;
  z-index: 3;
}
header .top-bar .search-icon img {
  width: 22px;
}
header .top-bar .menu-icon img {
  width: 24px;
}
header .top-bar ul:not(.User-settings-dropdown) {
  display: flex;
  align-items: center;
}
header .top-bar ul:not(.User-settings-dropdown) > li {
  padding: 0 10px;
}
header .top-bar ul:not(.User-settings-dropdown) > li:first-of-type {
  padding-left: 0;
}
header .top-bar ul:not(.User-settings-dropdown) > li:last-of-type {
  padding-right: 0;
}
header .top-bar ul:not(.User-settings-dropdown) > li a {
  font-size: 18px;
}
header .top-bar ul:not(.User-settings-dropdown) > li a:hover {
  color: #E1002A;
}
header .top-bar .User-settings-and-notifications {
  display: flex;
  align-items: center;
}
header .top-bar .User-settings-icon {
  width: 40px;
  height: 40px;
  overflow: hidden;
  padding: 0;
  border: 1px solid #05050514;
  border-radius: 50%;
  background: #000;
  color: #fed51d;
  font-size: 18px;
}
header .top-bar .User-settings-icon:after {
  content: none;
}
header .top-bar .User-settings-dropdown {
  padding: 0 0 20px;
}
header .top-bar .User-settings-dropdown li {
  width: 100%;
}
header .top-bar .User-settings-dropdown li a {
  padding: 20px 20px 0;
  white-space: nowrap;
}
header .top-bar .User-settings-dropdown li a:hover {
  background: #fff;
  text-decoration: none;
}
header .top-bar .left > li a {
  font-weight: 500;
}
header .top-bar .logo-block {
  margin-top: -12px;
}
header .top-bar .logo-block img {
  width: 100px;
}
header .navigation {
  padding: 10px 0;
  margin: 0 0 10px 0;
  border-bottom: 1px solid #efefef;
  justify-content: space-between;
  align-items: center;
}
header .navigation-links {
  flex: 1;
}
header .navigation-links li {
  padding: 0 15px;
}
header .navigation-links li:first-of-type {
  padding-left: 0;
}
header .navigation-links li:last-of-type {
  padding-right: 0;
}
header .navigation-links li a {
  font-size: 16px;
  line-height: 30px;
  text-transform: uppercase;
  color: #888;
}
header .navigation-links li a.active {
  color: #E1002A;
}
header .social-links {
  float: right;
  width: 12%;
}
header.mobile-header .right li {
  padding: 0;
}
header.mobile-header .right li .ellipsis-vertical {
  font-size: 24px;
}

.ad-space {
  border: 1px solid #AFAFAF;
  padding: 40px 0;
}
.ad-space p {
  font-size: 21px;
  line-height: 28px;
}
@media (max-width: 575.98px) {
  .ad-space p {
    font-size: 14px;
    line-height: 20px;
  }
}
.ad-space h2 {
  font-size: 64px;
  line-height: 100px;
}
@media (max-width: 575.98px) {
  .ad-space h2 {
    font-size: 36px;
    line-height: 64px;
  }
}

.ad-placement {
  width: 100%;
  height: 100%;
  background-color: #F5F5F5;
}

#subscribe {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 60px;
  background: #fff;
  width: 100%;
  -webkit-box-shadow: 0px -10px 13px 0px white;
  -moz-box-shadow: 0px -10px 13px 0px white;
  box-shadow: 0px -10px 13px 0px white;
}
#subscribe h2 {
  font-family: Oswald, sans-serif;
  font-size: 48px;
}
#subscribe h4 {
  font-size: 21px;
  padding: 15px 0 25px;
}
#subscribe .or {
  font-size: 21px;
  font-weight: 600;
  margin: 25px 0;
}
#subscribe .paid-subscriptions p {
  font-size: 21px;
  font-family: Oswald, sans-serif;
  margin: 0 0 20px;
}
#subscribe .paid-subscriptions .packages {
  display: flex;
}
#subscribe .paid-subscriptions .packages .package {
  border: 0.5px solid #000000;
  border-radius: 12px;
  padding: 15px;
  text-align: left;
  width: 200px;
  margin: 5px;
}
#subscribe .paid-subscriptions .packages .package .package-tag {
  font-size: 12px;
  font-family: Poppins, sans-serif;
  display: inline-block;
  background: #000;
  color: yellow;
  padding: 5px 15px;
  border-radius: 20px;
  font-weight: 900;
}
#subscribe .paid-subscriptions .packages .package .package-cost {
  font-family: Poppins, sans-serif;
  font-size: 24px;
  font-weight: 700;
}
#subscribe .paid-subscriptions .packages .package .package-description {
  font-size: 16px;
  font-family: Poppins, sans-serif;
  margin: 15px 0 10px;
}
#subscribe .paid-subscriptions .packages .package .choose-package-btn {
  padding: 8px;
  font-size: 14px;
  width: 100%;
  border-radius: 20px;
  text-transform: initial;
}
#subscribe .free-subscripitons {
  border: 2px solid #ccc;
  width: 570px;
  border-radius: 12px;
  padding: 10px 0 25px;
}
#subscribe .free-subscripitons h4 {
  font-family: Poppins, sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  padding: 10px 0 20px;
}
#subscribe .free-subscripitons .create-account-btn {
  background: #fff;
  border: 1px solid;
  padding: 10px 70px;
  border-radius: 25px;
  text-transform: initial;
}

.Web-form {
  font-family: Lora, sans-serif;
  font-size: 14px;
}
.Web-form label {
  margin: 0 0 10px;
}
.Web-form .form-control {
  border: 1px solid #000;
  padding: 12px 20px;
  border-radius: 8px;
  box-shadow: none;
}
.Web-form .form-control.is-invalid {
  border-color: #dc3545;
}
.Web-form .form-group {
  margin: 0 0 30px;
}

.alert.alert-error {
  font-family: Poppins, sans-serif;
  padding: 5px 10px;
  background: transparent;
  color: red;
  border: 0;
  font-size: 12px;
  letter-spacing: 1px;
}

.form-check label {
  font-family: Lora, sans-serif;
  text-transform: capitalize;
}
.form-check .form-check-input {
  width: 20px;
  height: 20px;
  margin-top: -1px;
  margin-right: 8px;
}
.form-check .form-check-input:checked {
  background-color: #fed51d;
  border-color: #fed51d;
}
.form-check .form-check-input:focus {
  border-color: #fed51d;
  box-shadow: none;
}

.article-tag-container {
  margin: 5px 0;
}

.article-tag {
  font-size: 12px;
  font-family: "Oswald";
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 2px;
  cursor: pointer;
}

.article-tag ion-icon {
  font-size: 16px;
  margin-top: -4px;
}

.tox.tox-tinymce {
  border: 1px solid #000;
  border-radius: 8px;
}

.char-counter {
  color: #aaa;
}

.owl-carousel .owl-nav.disabled {
  display: block;
}

.shopBannerCarousel .owl-item {
  background-color: #000000;
}
.shopBannerCarousel .carousel-control-next, .shopBannerCarousel .carousel-control-prev {
  opacity: 1;
  font-size: 48px;
}
.shopBannerCarousel.owl-theme .owl-nav {
  top: 50%;
  position: absolute;
  color: #fff;
  font-size: 140px;
  margin-top: -30px;
  width: 100%;
}
.shopBannerCarousel.owl-theme .owl-nav button {
  position: absolute;
}
.shopBannerCarousel.owl-theme .owl-nav button.owl-prev {
  left: 40px;
}
.shopBannerCarousel.owl-theme .owl-nav button.owl-next {
  right: 40px;
}
.shopBannerCarousel.owl-theme .owl-nav button:focus, .shopBannerCarousel.owl-theme .owl-nav button:hover {
  background: transparent;
}
.shopBannerCarousel .item {
  width: 100%;
  max-width: 100%;
  padding: 30px 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.shopBannerCarousel .item-image {
  width: 210px;
  height: 300px;
}
.shopBannerCarousel .item-image img {
  width: 100%;
  height: 100%;
}
.shopBannerCarousel .item-description {
  width: calc(100% - 280px);
}
.shopBannerCarousel .item-title {
  color: #fff;
  font-size: 48px;
}
.shopBannerCarousel .item-price {
  color: #FED51D;
  font-size: 24px;
  margin: 40px 0 10px;
}

.owl-carousel-wrapper {
  margin: 50px;
}

.carousel-wrapper-head {
  margin: 0 -50px 50px;
}
.carousel-wrapper-head .carousel-wrapper-title {
  font-family: Oswald, sans-serif;
  font-size: 48px;
}

.shopCarousel .item {
  text-align: center;
}
.shopCarousel .item-image {
  width: 140px;
  height: 200px;
  margin: 0 auto;
}
.shopCarousel .item-image img {
  width: 100%;
  height: 100%;
}
.shopCarousel .item-title {
  font-size: 21px;
  padding: 15px 30px;
  height: 85px;
  overflow: hidden;
}
.shopCarousel .item-author {
  font-size: 14px;
  font-weight: 400;
}
.shopCarousel.owl-theme .owl-nav {
  top: 30%;
  position: absolute;
  width: 100%;
  font-size: 70px;
}
.shopCarousel.owl-theme .owl-nav button {
  position: absolute;
}
.shopCarousel.owl-theme .owl-nav button:hover {
  background: 0 0;
  color: #000;
}
.shopCarousel.owl-theme .owl-nav button.owl-prev {
  left: -40px;
}
.shopCarousel.owl-theme .owl-nav button.owl-next {
  right: -40px;
}

.shop-filters {
  width: 250px;
}
.shop-filters .filters-head {
  margin: 20px 0;
}
.shop-filters .filters .form-group {
  margin: 0 0 20px;
}
.shop-filters .filters .form-group label {
  font-size: 21px;
  text-transform: capitalize;
  font-weight: 600;
  line-height: 24px;
  margin: 0 0 15px;
}
.shop-filters .filters .form-group label.form-check-label {
  font-size: 14px;
  font-family: Oswald, sans-serif;
  text-transform: uppercase;
  font-weight: normal;
  margin: 0 0 2px;
  color: #E1002A;
}
.shop-filters .filters .form-group .form-check-input {
  margin-top: 0;
  margin-right: 10px;
}

.shop-books-and-magazines {
  width: calc(100% - 300px);
  flex-wrap: wrap;
  place-self: flex-start;
}
.shop-books-and-magazines .shop-item {
  width: 33%;
  margin: 20px 0;
}
.shop-books-and-magazines .shop-item .item-description {
  padding-right: 20px;
}
.shop-books-and-magazines .shop-item .shop-item-title {
  font-size: 21px;
  line-height: 28px;
  height: 56px;
  overflow: hidden;
}
.shop-books-and-magazines .shop-item .shop-item-author {
  font-size: 12px;
  margin: 0 0 30px;
  height: 20px;
  overflow: hidden;
}
.shop-books-and-magazines .shop-item .shop-item-price {
  font-size: 12px;
}
.shop-books-and-magazines .shop-item .simple-link {
  font-size: 12px;
  display: block;
  padding: 5px 0;
  text-decoration: none;
}
.shop-books-and-magazines .shop-item .btn {
  font-size: 12px;
  padding: 5px 15px;
}
.shop-books-and-magazines .item-image {
  width: 125px;
  height: 180px;
}
.shop-books-and-magazines .item-image img {
  width: 100%;
  height: 100%;
}
.shop-books-and-magazines .item-description {
  width: calc(100% - 150px);
}

.shop-single-item-page .shop-item-images figure {
  width: 100%;
  height: 400px;
}
.shop-single-item-page .shop-item-images figure img {
  width: 100%;
  height: 100%;
}
.shop-single-item-page .shop-item-images .button-group {
  margin: 40px 0;
}
.shop-single-item-page .shop-item-images button {
  width: 100%;
  margin: 5px 0;
}
.shop-single-item-page .shop-item-images .other-images {
  display: flex;
  justify-content: space-between;
}
.shop-single-item-page .shop-item-images .other-images img {
  width: 56px;
  height: 80px;
}
.shop-single-item-page .shop-item-details .single-title {
  font-weight: 700;
  font-size: 70px;
}
.shop-single-item-page .shop-item-details .single-author {
  text-transform: uppercase;
}
.shop-single-item-page .shop-item-details .single-issn-volume {
  font-size: 21px;
  margin: 30px 0;
}
.shop-single-item-page .shop-item-details .single-issn-volume span {
  display: inline-block;
}
.shop-single-item-page .shop-item-details .single-issn-volume span:first-of-type {
  margin-right: 50px;
}
.shop-single-item-page .shop-item-details .single-price-block h2 {
  display: inline-block;
  margin-right: 20px;
}
.shop-single-item-page .shop-item-details .single-price-block .discounted_price {
  font-weight: 700;
  font-size: 48px;
}
.shop-single-item-page .shop-item-details .single-price-block .original_price {
  font-size: 36px;
}
.shop-single-item-page .shop-item-details .single-price-block .original_price span {
  text-decoration: line-through;
}
.shop-single-item-page .shop-item-details .single-price-block .taxes {
  color: #0B8C56;
  font-size: 14px;
  text-transform: uppercase;
}
.shop-single-item-page .shop-item-details .single-quantity {
  margin: 40px 0;
  font-size: 36px;
  font-weight: 600;
  color: #0B8C56;
}
.shop-single-item-page .shop-item-details .single-quantity .quantity {
  color: initial;
  display: inline-block;
  margin-right: 80px;
}
.shop-single-item-page .shop-item-details .single-quantity .quantity input {
  width: 70px;
  border-radius: 4px;
  font-size: 16px;
  padding: 12px 10px;
  border: 1px solid;
  margin: 0 10px;
}
.shop-single-item-page .shop-item-details .extra-actions {
  margin: 10px 0;
}
.shop-single-item-page .single-product-extra-details {
  margin: 50px 0;
}
.shop-single-item-page .single-product-extra-details ul li {
  display: inline-block;
  width: 33%;
  font-size: 21px;
  font-family: Oswald, sans-serif;
  margin: 15px 0;
}
.shop-single-item-page .single-product-extra-details ul li span {
  font-family: Poppins, sans-serif;
  margin-left: 20px;
}

.user-reviews-section .add-review h3 {
  font-size: 21px;
  font-weight: 600;
}
.user-reviews-section .add-review .review-comment {
  margin: 50px 0;
}
.user-reviews-section .add-review .review-comment .form-control {
  background: #FFFFFF;
  box-shadow: inset 4px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  padding: 20px;
}
.user-reviews-section .add-review .review-comment label {
  margin: 20px 0 10px;
}
.user-reviews-section .add-review .review-comment button {
  margin-top: 20px;
}
.user-reviews-section .reviews {
  padding-left: 100px;
}
.user-reviews-section .reviews .review-block {
  margin: 0 0 50px;
}
.user-reviews-section .reviews .review-block .user-name-image {
  font-size: 18px;
  font-weight: 500;
}
.user-reviews-section .reviews .review-block .user-comment-title {
  font-size: 21px;
  font-weight: 600;
}
.user-reviews-section .reviews .review-block .user-comment {
  margin: 20px 0;
}
.user-reviews-section .reviews .review-block .review-actions a {
  padding: 0;
  border-radius: 5px;
  width: 132px;
  height: 40px;
  text-align: center;
  line-height: 38px;
}
.user-reviews-section .reviews .review-block .review-actions a:first-of-type {
  border: 1px solid #ccc;
}

.cart-page {
  margin: 40px 0;
}
.cart-page .cart-items {
  width: 100%;
}
.cart-page tr.cart-item {
  background: #FFFFFF;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
}
.cart-page tr.cart-item.cart-items-head {
  box-shadow: none;
}
.cart-page tr.cart-item th, .cart-page tr.cart-item td {
  font-size: 16px;
  line-height: 27px;
  font-weight: normal;
  color: #686868;
  text-transform: uppercase;
  padding: 25px 4px;
  text-align: center;
}
.cart-page tr.cart-item th:first-of-type, .cart-page tr.cart-item td:first-of-type {
  padding-left: 25px;
  text-align: left;
}
.cart-page tr.cart-item .item-description {
  position: relative;
  min-height: 100px;
}
.cart-page tr.cart-item .item-description figure {
  width: 84px;
  height: 120px;
  float: left;
  margin-right: 40px;
}
.cart-page tr.cart-item .item-description figure img {
  width: 100%;
  height: 100%;
}
.cart-page tr.cart-item .item-description .item-details {
  float: left;
}
.cart-page tr.cart-item .item-description .item-details h4 {
  font-family: Oswald, sans-serif;
  font-size: 21px;
  line-height: 24px;
  margin-bottom: 3px;
}
.cart-page tr.cart-item .item-description .item-details p {
  font-size: 18px;
  font-weight: normal;
  text-transform: initial;
}
.cart-page tr.cart-item .item-description .item-details .actions {
  position: absolute;
  bottom: 0;
  left: 124px;
}
.cart-page tr.cart-item .item-description .item-details .actions .cart-item-action {
  border: 0;
  padding: 0;
  font-size: 14px;
  text-transform: uppercase;
  padding-right: 50px;
}
.cart-page tr.cart-item .item-description .item-details .actions .cart-item-action:hover {
  color: #E1002A;
}
.cart-page tr.cart-item .item-description .item-details .actions .cart-item-action ion-icon {
  font-size: 16px;
  vertical-align: -1px;
}
.cart-page tr.cart-item .item-quantity .incdecbtn {
  border: 2px solid #292D32;
  font-size: 12px;
  width: 20px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  line-height: 19px;
  color: #000000;
  background-color: #FED51D;
  margin: 2px;
  border-radius: 6px;
  cursor: pointer;
  padding: 0;
}
.cart-page tr.cart-item .item-quantity .incdecbtn.item-count {
  line-height: 17px;
  background-color: #FFF;
  cursor: initial;
  font-size: 13px;
}
.cart-page tr.cart-item .item-unit-price, .cart-page tr.cart-item .item-total-price {
  border-left: 1.5px solid #E1E1E1;
}
.cart-page .cart-total-block {
  text-align: right;
}
.cart-page .cart-total-block h3 {
  padding: 50px 30px 50px 0;
}
.cart-page .cart-total-block .cta-block .btn:first-of-type {
  margin-left: 15px;
}
.cart-page .cart-total-block .cta-block .btn {
  padding: 10px 48px;
}

/**
Checkout page
 */
.cart-page .shopped-items .cart-total-block .cta-block .btn:first-of-type {
  margin: 0;
}
.cart-page .shopped-items .cart-total-block h3 {
  padding: 50px 0;
  font-size: 18px;
  color: #898989;
}
.cart-page .shopped-items .cart-total-block h3 #cart-total {
  font-size: 1.75rem;
  color: #000;
  margin-left: 20px;
}
.cart-page .shopped-items .item-quantity {
  white-space: nowrap;
}
.cart-page .shopped-items tr.cart-item .item-description .item-details .actions {
  position: relative;
  left: 0;
}
.cart-page .shopped-items tr.cart-item .item-description figure {
  width: 70px;
  height: 100px;
  margin-right: 20px;
}
.cart-page .shopped-items tr.cart-item .item-description .item-details {
  float: left;
  width: calc(100% - 90px);
}
.cart-page .shopped-items tr.cart-item td {
  padding: 20px 10px;
}
.cart-page .shopped-items tr.cart-item td:first-of-type {
  padding-left: 20px;
}

.shipping-details {
  margin-top: 75px;
  background: #FFFFFF;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
  padding: 30px;
}
.shipping-details .form-group {
  margin: 25px 0;
}

.section-inner-title {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  text-transform: uppercase;
}

.hero-blocks .block {
  width: 100%;
}
.hero-blocks .block:nth-of-type(2) {
  width: 300%;
  margin: 0 30px;
}
@media (max-width: 575.98px) {
  .hero-blocks .block:nth-of-type(2) {
    width: 100%;
    margin: 0 0 20px;
  }
}
@media (max-width: 575.98px) {
  .hero-blocks .block {
    width: 100%;
    margin: 0 0 20px;
  }
}

.news_title {
  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
}

.recent_news_block {
  padding: 40px 20px;
}
.recent_news_block .recent_news_item {
  margin: 15px 0;
}
@media (max-width: 575.98px) {
  .recent_news_block .recent_news_title {
    font-size: 16px;
  }
}
.recent_news_block .news_image {
  margin: 0 0 10px;
  height: 145px;
  overflow: hidden;
}

.highlight_news {
  padding: 40px;
}
.highlight_news .item .item_tag {
  font-weight: 700;
}
@media (max-width: 575.98px) {
  .highlight_news .item .item_tag {
    font-size: 14px;
    line-height: 18px;
  }
}
.highlight_news .item .item_title {
  font-size: 48px;
  font-weight: 700;
  line-height: 72px;
}
@media (max-width: 575.98px) {
  .highlight_news .item .item_title {
    font-size: 22px;
    line-height: 30px;
    margin-bottom: 10px;
  }
}
.highlight_news .item .item_description {
  line-height: 24px;
  font-size: 16px;
}
@media (max-width: 575.98px) {
  .highlight_news .item .item_description {
    height: 70px;
    overflow: hidden;
  }
}
.highlight_news .item .item_author {
  padding: 20px 0;
}
.highlight_news .item .item_image {
  width: 100%;
}

.category_news .category_news_item {
  border-bottom: 1px solid #ccc;
  padding: 20px 0;
}
@media (max-width: 575.98px) {
  .category_news .category_news_item {
    padding: 10px;
  }
}
.category_news .category_news_item:first-of-type {
  padding-top: 0;
}
.category_news .category_news_item:last-of-type {
  border-bottom: 0;
}
.category_news .news_title {
  margin: 5px 0 10px;
  display: block;
}
.category_news .author {
  padding: 5px 0;
}

.articles {
  padding: 40px 0;
  position: relative;
  margin-right: 40px;
  width: 60%;
}
.articles:before {
  content: "";
  width: calc(100% + 100%);
  height: 100%;
  background-color: #F5F5F5;
  display: inline-block;
  position: absolute;
  z-index: -1;
  padding-left: 100px;
  margin-left: -100%;
  top: 0;
  bottom: 0;
}
@media (max-width: 575.98px) {
  .articles {
    width: 100%;
    padding: 20px;
    background-color: #F5F5F5;
    margin: 0;
  }
  .articles:before {
    content: none;
  }
}
.articles .articles-left {
  width: 47%;
  float: left;
  padding-right: 20px;
}
.articles .articles-right {
  width: 53%;
  float: left;
  padding: 0 40px 0 20px;
}
.articles .article-card {
  padding: 30px 0;
  border-bottom: 1px solid #CDCDCD;
}
.articles .article-card:last-of-type {
  padding-bottom: 5px;
  border: 0;
}
.articles .article-card .article-image {
  margin: 0 0 15px;
}
.articles .article-card .article-tag {
  text-transform: uppercase;
  font-size: 14px;
  line-height: 18px;
}
.articles .article-card .article-title {
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
  padding: 0 0 10px;
}
.articles .article-card .article-issn {
  text-transform: uppercase;
  font-size: 20px;
  line-height: 24px;
}
.articles .article-card .article-volume-no {
  text-transform: uppercase;
  font-size: 20px;
  line-height: 24px;
}
.articles .article-card .article-description {
  font-size: 14px;
  padding: 15px 0 10px;
}
.articles .article-card .article-author {
  font-size: 12px;
}

@media (max-width: 575.98px) {
  .books-and-articles {
    margin: 0 -15px;
  }
}
.books-section {
  padding: 40px;
  width: 40%;
}
@media (max-width: 575.98px) {
  .books-section {
    width: 100%;
    padding: 20px;
  }
}
.books-section .read_more_btn_wrap {
  margin-top: 30px;
}

.book-card {
  padding: 20px;
  border: 1px solid #000;
  margin: 15px 0;
}
@media (max-width: 575.98px) {
  .book-card {
    padding: 10px;
  }
}
.book-card:last-of-type {
  margin-bottom: 0;
}
.book-card .book-image {
  width: 50%;
}
.book-card .book-image img {
  width: 100%;
  height: 100%;
}
.book-card .book-details {
  width: 50%;
  padding: 0 20px;
}
@media (max-width: 575.98px) {
  .book-card .book-details {
    padding: 0 0 0 15px;
  }
}
.book-card .book-title {
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
}
@media (max-width: 575.98px) {
  .book-card .book-title {
    font-size: 16px;
    line-height: 22px;
  }
}
.book-card .book-description {
  padding: 5px 0 0;
}
.book-card .book-description:last-of-type {
  padding-bottom: 10px;
}
@media (max-width: 575.98px) {
  .book-card .book-description {
    height: 90px;
    overflow: hidden;
    padding: 0;
    margin: 5px 0 10px;
  }
}
.book-card .btn {
  padding: 5px 20px;
}
@media (max-width: 575.98px) {
  .book-card .btn {
    padding: 5px 15px;
    font-size: 14px;
  }
}

.read_more_btn_wrap.position-absolute {
  bottom: 40px;
  right: 40px;
}

.categroy-block {
  width: 100%;
  padding: 0 40px 40px;
  border-right: 1px solid #CDCDCD;
}
.categroy-block:first-of-type {
  padding-left: 0;
}
.categroy-block:last-of-type {
  padding-right: 0;
  border: 0;
  margin: 0;
}
@media (max-width: 575.98px) {
  .categroy-block {
    padding: 0;
    border: 0;
    margin: 0 0 30px;
  }
}
.categroy-block .section-inner-title {
  font-size: 24px;
  line-height: 30px;
}
@media (max-width: 575.98px) {
  .categroy-block .section-inner-title {
    font-size: 22px;
    line-height: 28px;
  }
}
.categroy-block .category-article.main {
  width: 100%;
  height: 250px;
  margin: 30px 0;
}
@media (max-width: 575.98px) {
  .categroy-block .category-article.main {
    margin: 15px 0;
  }
}
.categroy-block .category-article.main .details {
  left: 20px;
  bottom: 20px;
}
.categroy-block .category-article.sub-main {
  margin: 0 0 30px;
}
@media (max-width: 575.98px) {
  .categroy-block .category-article.sub-main {
    margin: 0 0 15px;
  }
}
.categroy-block .category-article.sub-main figure {
  width: 150px;
  height: 130px;
}
.categroy-block .category-article.sub-main figure img {
  width: 100%;
  height: 100%;
}
.categroy-block .category-article.sub-main .details-sub-main {
  padding: 0 20px;
  width: calc(100% - 150px);
}
.categroy-block .category-article.sub-main .details-sub-main .category-article-title {
  height: 72px;
  overflow: hidden;
}
.categroy-block .category-article .author {
  font-size: 12px;
}
@media (max-width: 575.98px) {
  .categroy-block .category-article .author {
    font-size: 10px;
  }
}
.categroy-block .category-article .category-article-title {
  margin-bottom: 15px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
}
.categroy-block .category-article.listing li {
  padding: 15px 0;
  border-top: 1px solid #CDCDCD;
  height: 80px;
  overflow: hidden;
}
@media (max-width: 575.98px) {
  .categroy-block .category-article.listing li {
    padding: 10px 0;
  }
}
.categroy-block .category-article.listing li ion-icon {
  font-size: 16px;
  vertical-align: -2px;
}
.categroy-block .category-article.listing .category-article-title {
  margin: 0;
}
.editors-pick {
  margin: 20px 0 0;
  padding: 60px 0 40px;
}

.editors-pick-title-wrap {
  position: relative;
  margin-bottom: 20px;
}
.editors-pick-title-wrap .editors-pick-title {
  font-size: 70px;
  font-weight: bold;
}
@media (max-width: 575.98px) {
  .editors-pick-title-wrap .editors-pick-title {
    font-size: 32px;
    padding: 0 50px;
  }
}
.editors-pick-title-wrap .quote {
  width: 64px;
  position: absolute;
}
@media (max-width: 575.98px) {
  .editors-pick-title-wrap .quote {
    width: 25px;
  }
}
.editors-pick-title-wrap .quote.quote-left {
  left: -75px;
  top: -20px;
}
@media (max-width: 575.98px) {
  .editors-pick-title-wrap .quote.quote-left {
    left: 20px;
    top: -10px;
  }
}
.editors-pick-title-wrap .quote.quote-right {
  right: -75px;
  bottom: -20px;
}
@media (max-width: 575.98px) {
  .editors-pick-title-wrap .quote.quote-right {
    right: 20px;
    bottom: -10px;
  }
}

.editors-pick-articles {
  justify-content: center;
}
@media (max-width: 575.98px) {
  .editors-pick-articles {
    width: 100%;
    justify-content: space-around;
  }
}
.editors-pick-articles .pick-article {
  margin: 20px;
  width: 18%;
}
@media (max-width: 575.98px) {
  .editors-pick-articles .pick-article {
    margin: 15px 0 0;
    width: 43%;
  }
}
.editors-pick-articles .pick-article img {
  width: 240px;
  height: 160px;
}
.editors-pick-articles .pick-article .description {
  padding: 10px 0;
}
.editors-pick-articles .pick-article .author {
  font-size: 10px;
}

.expert-article {
  margin: 20px;
  width: 25%;
}
.expert-article img {
  width: 305px;
  height: 225px;
}
.expert-article:first-of-type {
  margin-left: 0;
}
.expert-article:last-of-type {
  margin-right: 0;
}
@media (max-width: 575.98px) {
  .expert-article {
    margin: 15px 0;
    width: 48%;
  }
}
.expert-article .category-tag {
  padding: 10px 0;
  display: inline-block;
}
.expert-article .expert-article-description {
  font-size: 16px;
  line-height: 24px;
  display: block;
  height: 72px;
  overflow: hidden;
}
@media (max-width: 575.98px) {
  .expert-article .expert-article-description {
    font-size: 14px;
    line-height: 20px;
  }
}
.expert-article .expert-article-description:hover {
  text-decoration: underline;
}
.expert-article .expert-name {
  padding: 5px 0;
  text-transform: uppercase;
}

.article-head .authors {
  line-height: 36px;
  font-size: 18px;
}
.article-head .article_publish_date {
  line-height: 24px;
  font-size: 17px;
}

.fancybox__figure__name, .fancybox__caption {
  font-size: 18px;
  margin: 10px 0 20px;
  text-align: center;
}

.article-page, .category-page {
  padding-right: 80px;
}
.article-page .article-title, .category-page .article-title {
  font-weight: 700;
  font-size: 40px;
}
.article-page .article-author, .category-page .article-author {
  font-weight: 500;
  padding: 5px 0 5px;
}
.article-page .article-author span, .category-page .article-author span {
  font-weight: 300;
}
.article-page .article_abstract, .category-page .article_abstract {
  line-height: 28px;
  font-size: 18px;
  font-family: "PT Serif", serif;
}
.article-page .about_author, .category-page .about_author {
  font-weight: 600;
  line-height: 28px;
  font-size: 18px;
  font-family: "PT Serif", serif;
}
.article-page .article-actions, .category-page .article-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 15px;
}
.article-page .article-actions .social-actions li, .category-page .article-actions .social-actions li {
  display: inline-block;
}
.article-page .article-actions .social-actions li a, .category-page .article-actions .social-actions li a {
  font-size: 20px;
  background: #000;
  color: #fff;
  padding: 12px;
  border-radius: 50%;
  margin: 0 5px;
}
.article-page .article-actions .social-actions li a:first-of-type, .category-page .article-actions .social-actions li a:first-of-type {
  margin-left: 0;
}
.article-page .article-actions .other-actions .btn.btn-primary, .category-page .article-actions .other-actions .btn.btn-primary {
  border: 0;
  color: #000000;
}
.article-page .article-actions .other-actions .btn.btn-primary.btn-download, .category-page .article-actions .other-actions .btn.btn-primary.btn-download {
  background-color: #FED51D;
}
.article-page .article-actions .other-actions .btn.btn-primary.btn-download ion-icon, .category-page .article-actions .other-actions .btn.btn-primary.btn-download ion-icon {
  font-size: 22px;
  vertical-align: -3px;
  margin: 0 5px;
}
.article-page .article-featured-image, .category-page .article-featured-image {
  margin: 0 0 15px;
}
.article-page .article-featured-image img, .category-page .article-featured-image img {
  width: 100%;
}
.article-page .other-article-details, .category-page .other-article-details {
  margin: 15px 0;
}
.article-page .other-article-details ul, .category-page .other-article-details ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.article-page .other-article-details ul p, .category-page .other-article-details ul p {
  font-family: Oswald, sans-serif;
  font-weight: 400;
}
.article-page .article-description p, .category-page .article-description p {
  padding: 0 0 15px;
  line-height: 28px;
  font-size: 18px;
  font-family: "PT Serif", serif;
}
.article-page .article-description p a, .category-page .article-description p a {
  color: #0d6efd;
}
.article-page .article-description h4 + p, .category-page .article-description h4 + p {
  padding: 0 !important;
}
.article-page .article-description figure.image, .category-page .article-description figure.image {
  margin: 20px 0;
  text-align: center !important;
}
.article-page .article-description figure.image figcaption, .category-page .article-description figure.image figcaption {
  width: 90%;
  margin: 0 auto;
  font-size: 18px;
}
.article-page .article-description figure.image figcaption h6, .category-page .article-description figure.image figcaption h6 {
  font-size: 16px;
}
.article-page .article-description .article_publish_date strong, .category-page .article-description .article_publish_date strong {
  display: inline-block;
  margin: -3px 0 0;
}
.article-page .article-description h4, .category-page .article-description h4 {
  font-weight: bold;
  margin-bottom: 15px;
  margin-top: 20px;
}
.article-page .article-description h5, .category-page .article-description h5 {
  font-size: 16px;
  font-weight: bold;
}
.article-page .article-description ul, .article-page .article-description ol, .category-page .article-description ul, .category-page .article-description ol {
  margin: 0 0 1rem;
  padding-left: 2rem;
  list-style: initial;
}
.article-page .article-description ul li, .article-page .article-description ol li, .category-page .article-description ul li, .category-page .article-description ol li {
  line-height: 28px;
  font-size: 18px;
  position: relative;
}
.article-page .article-description ol, .category-page .article-description ol {
  list-style: decimal;
}
.article-page .article-description img.full-width-image, .category-page .article-description img.full-width-image {
  width: 90%;
  height: auto;
  margin: 0 0 5px;
}
.article-page .article-description .article_references a, .category-page .article-description .article_references a {
  color: initial;
}
.article-page .article-description .article_references p, .category-page .article-description .article_references p {
  position: relative;
}
.article-page .article-description .article_references .g-anchor, .category-page .article-description .article_references .g-anchor {
  position: absolute;
  top: 0;
}

.section-more-articles.category-related-articles {
  margin-top: 40px;
  padding: 0 80px 0 0;
}
.section-more-articles .more-articles-title {
  font-family: Oswald, sans-serif;
  font-size: 40px;
  line-height: 48px;
  font-weight: 400;
  margin-top: 8px;
}
.section-more-articles .article-card {
  padding: 15px 0;
  border-bottom: 1px solid #f3f3f3;
}
.section-more-articles .article-card.impact-article-card {
  padding: 20px;
  border: 0;
  width: 25%;
}
.section-more-articles .article-card.impact-article-card:first-of-type {
  padding-left: 0;
}
.section-more-articles .article-card.impact-article-card:last-of-type {
  padding-right: 0;
}
.section-more-articles .article-card.impact-article-card h4.title {
  height: 72px;
  overflow: hidden;
  margin: 0 0 12px;
}
.section-more-articles .article-card:first-of-type {
  padding-top: 25px;
}
.section-more-articles .article-card:last-of-type {
  border-bottom: 0;
}
.section-more-articles .article-card figure {
  margin: 0px 0 15px;
}
.section-more-articles .article-card .tag {
  margin: 0 0 5px;
}
.section-more-articles .article-card .title {
  font-size: 20px;
  line-height: 24px;
  margin: 5px 0;
}
.section-more-articles .article-card .description {
  font-family: "Lora";
  color: #000000;
  margin: 10px 0;
}
.section-more-articles .article-card .author em {
  display: inline-block;
  font-weight: 300;
  color: #666;
  margin: -2px 3px 0 0;
}

.recommended-books {
  margin: 30px 0;
}
.recommended-books .rb-card {
  padding: 30px;
  background: #FFFFFF;
  box-shadow: 1.03103px 4.12414px 15px rgba(130, 130, 130, 0.18);
  border-radius: 10px;
  margin: 0 5px;
}
.recommended-books .rb-card img {
  width: 185px;
  height: 205px;
}
.recommended-books .rb-card h5 {
  font-size: 14px;
  color: #333;
  line-height: 21px;
  margin: 15px 0;
}
.recommended-books .rb-card a.add-to-cart-link {
  display: block;
  margin: 0 0 5px;
}
.recommended-books .rb-card a.buy-now-btn {
  font-size: 14px;
  padding: 5px 15px;
  border-radius: 10px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.account-page {
  display: flex;
  align-items: center;
  flex-flow: column;
  height: 100vh;
  justify-content: center;
}
.account-page h4 {
  font-family: Lora, sans-serif;
  margin: 15px 0 0;
  font-size: 22px;
}
.account-page h4 a {
  margin-top: -5px;
}
.account-page .or {
  font-size: 21px;
  line-height: 24px;
  font-weight: 600;
  text-align: center;
  margin: 35px 0 30px;
  position: relative;
}
.account-page .or:before {
  content: "";
  display: inline-block;
  width: 120px;
  position: absolute;
  background: #000;
  height: 2px;
  left: 0;
  top: 50%;
  margin-top: -1px;
  transform: translateX(40%);
}
.account-page .or:after {
  content: "";
  display: inline-block;
  width: 120px;
  position: absolute;
  background: #000;
  height: 2px;
  right: 0;
  top: 50%;
  margin-top: -1px;
  transform: translateX(-40%);
}
.account-page .login-platform-btn {
  width: 100%;
  border-radius: 10px;
  padding: 13px 10px;
  margin: 0 0 20px;
  border: 0;
  font-size: 16px;
}
.account-page .login-platform-btn img {
  margin: -1px 10px 0 0;
}
.account-page .login-platform-btn:last-of-type {
  margin: 0;
}
.account-page .login-platform-btn.login-with-google {
  background: #FFFFFF;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.08), 0px 2px 3px rgba(0, 0, 0, 0.17);
}
.account-page .login-platform-btn.login-with-fb {
  background: #1877F2;
  color: #fff;
}
.account-page .form-wrapper {
  width: 600px;
  max-width: 100%;
  text-align: left;
  margin: 30px auto;
  background: #FFFFFF;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  padding: 35px 50px;
}
.account-page button[type=submit] {
  width: 100%;
}
.account-page .form-group {
  margin: 0 0 25px;
}
.account-page .form-group label {
  font-family: Oswald, sans-serif;
  font-weight: 400;
  margin: 0 0 10px;
  text-transform: uppercase;
  font-size: 14px;
}
.account-page .form-group .form-control {
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.62);
  background: #fff;
  padding: 12px 15px;
  border-radius: 8px;
  border: 1px solid #000;
  font-size: 14px;
  font-family: poppins, sans-serif;
  color: #000;
}
.account-page .form-group .form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-size: 14px;
  font-family: Lora, sans-serif;
}
.account-page .form-group .form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-size: 14px;
  font-family: Lora, sans-serif;
}
.account-page .form-group .form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  font-size: 14px;
  font-family: Lora, sans-serif;
}

.User-dashboard aside, .User-dashboard main {
  background: #FFFFFF;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  min-height: 100vh;
}
.User-dashboard .title {
  font-size: 21px;
  line-height: 24px;
  font-weight: 600;
  margin: 0 0 50px;
}
.User-dashboard .User-dashboard-sidebar {
  padding: 50px 30px;
}
.User-dashboard .User-dashboard-sidebar .sidebar-links {
  margin: 50px -30px 0;
}
.User-dashboard .User-dashboard-sidebar .sidebar-link {
  width: 100%;
}
.User-dashboard .User-dashboard-sidebar .sidebar-link-item {
  font-size: 14px;
  font-family: oswald, sans-serif;
  letter-spacing: 1px;
  padding: 15px 30px;
  width: 100%;
  border: 0;
  text-align: left;
  border-radius: 0;
  color: #000000;
}
.User-dashboard .User-dashboard-sidebar .sidebar-link-item:hover, .User-dashboard .User-dashboard-sidebar .sidebar-link-item.active {
  background-color: #FED51D;
  text-decoration: none;
  color: #E1002A;
}
.User-dashboard .User-dashboard-sidebar .sidebar-link-item ion-icon {
  font-size: 22px;
  margin: -3px 10px 0 0;
}
.User-dashboard .User-dashboard-main {
  padding: 50px;
}
.User-dashboard .Web-form .col-md-6:nth-of-type(2n+1) {
  padding-right: 30px;
}
.User-dashboard .Web-form .col-md-6:nth-of-type(2n+2) {
  padding-left: 30px;
}
.User-dashboard .Web-form .form-group {
  margin: 20px 0;
}

.contact-us-page .our-details p {
  line-height: 20px;
  padding-right: 50px;
}
.contact-us-page .our-details .ctas {
  margin: 50px 0;
}
.contact-us-page .our-details .ctas a {
  color: #000;
}
.contact-us-page .our-details .map {
  margin: 50px 0 0;
}
.contact-us-page .contact-form {
  background: #FFFFFF;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
  padding: 50px;
}
.contact-us-page .contact-form h4 {
  margin: 0 0 40px;
}

.members .member {
  display: flex;
  align-items: center;
  margin: 50px 0;
}
.members .member figure {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  overflow: hidden;
}
.members .member figure img {
  width: 100%;
  height: 100%;
}
.members .member .member-detail {
  width: calc(100% - 300px);
  padding: 0 150px 0 70px;
}
.members .member .member-detail .member-name {
  font-weight: 600;
  font-size: 48px;
  line-height: 72px;
  margin-bottom: 25px;
}
.members .member .member-detail p {
  font-family: Lora, sans-serif;
}
.members .member:nth-of-type(2n) figure {
  order: 2;
}
.members .member:nth-of-type(2n) .member-detail {
  padding: 0 70px 0 150px;
}

.policy-terms {
  font-family: Lora, sans-serif;
}
.policy-terms ul {
  margin: 30px 0;
  list-style: disc;
  padding: 0 0 0 20px;
}

.author-search-form input {
  border: 0;
  width: 482px;
  height: 50px;
  background: #FFFFFF;
  box-shadow: 4px 4px 21px rgba(0, 0, 0, 0.25);
  border-radius: 56px;
  padding: 15px 25px;
  font-family: Lora, sans-serif;
  font-size: 15px;
}
.author-search-form button {
  border-radius: 25px;
  position: absolute;
  right: 4px;
  width: 116px;
  height: 42px;
  font-size: 12px;
  text-align: center;
  padding: 0;
  line-height: 42px;
  top: 4px;
}

.directory-characters .char {
  font-size: 70px;
  font-weight: 700;
  line-height: 90px;
  margin-right: 40px;
}

.after-checkout-page h3 {
  margin: 0 0 20px;
}
.after-checkout-page p {
  padding: 10px 0;
  font-size: 16px;
}

.single-author .about-author {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px 0 70px;
}
.single-author .author-image {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  border: 1px solid #eee;
  overflow: hidden;
  box-shadow: 0px 0px 8px #e9e9e9;
}
.single-author .author-image img {
  width: 100%;
  height: 100%;
}
.single-author .author-details {
  width: calc(100% - 300px);
  padding-left: 150px;
}
.single-author .author-description h2 {
  font-size: 48px;
  font-family: Poppins, sans-serif;
  font-weight: 700;
  margin: 0 0 30px;
}
.single-author .author-description p {
  font-size: 16px;
  font-family: Lora, sans-serif;
  line-height: 22px;
}
.single-author .author-description .descp a {
  margin-top: -3px;
  color: #0d6efd;
  text-decoration: underline;
}
.single-author .author-social-links {
  padding: 50px 0 0;
}
.single-author .author-social-links .social-link {
  width: 36px;
  height: 36px;
  background: #000;
  color: #fff;
  line-height: 34px;
  text-align: center;
  border-radius: 50%;
  font-size: 18px;
  margin: 0 15px 0 0;
}
.single-author .articles-by-author {
  background: #F5F5F5;
  padding: 25px 0;
}
.single-author .articles-by-author .author-article {
  padding: 25px 0;
  display: flex;
}
.single-author .articles-by-author .author-article .author-article-details {
  width: calc(100% - 330px);
  padding-right: 100px;
}
.single-author .articles-by-author .author-article .author-article-image {
  width: 330px;
  display: inline-block;
}
.single-author .articles-by-author .author-article .author-article-image img {
  width: 100%;
  height: 100%;
}
.single-author .articles-by-author .author-article .article-title {
  font-size: 32px;
  font-weight: 700;
  margin: 5px 0 20px;
  display: block;
  line-height: 40px;
}
.single-author .articles-by-author .author-article .article-description {
  font-family: Lora, sans-serif;
  font-size: 16px;
  line-height: 22px;
}
.single-author .articles-by-author .author-article .simple-link {
  padding: 10px 0 0;
}

.subscription-plans-page {
  margin: 80px 0 40px;
}
.subscription-plans-page .plan-cards {
  display: flex;
  justify-content: center;
}
.subscription-plans-page .plan-card {
  width: 300px;
  max-width: 100%;
  border: 0;
  padding: 50px 12px;
}
.subscription-plans-page .plan-card .btn {
  width: 100%;
  padding: 5px 10px;
  border-radius: 20px;
  margin: 30px 0 0;
  text-transform: initial;
}
.subscription-plans-page .plan-card.favorite-plan {
  border: 2px solid #111111;
  border-radius: 25px;
  padding: 60px 12px;
}
.subscription-plans-page .plan-card .pricing {
  margin-bottom: 20px;
}
.subscription-plans-page .plan-card .pricing h1 {
  font-size: 42px;
  line-height: 38px;
  display: inline-block;
  font-weight: 700;
  color: #231D4F;
}
.subscription-plans-page .plan-card .pricing span {
  display: block;
  font-size: 18px;
  color: #bdbcc3;
}
.subscription-plans-page .plan-card .plan-description {
  color: #848199;
  padding: 10px 0 15px;
  line-height: 21px;
}
.subscription-plans-page .plan-card ul li {
  font-size: 15px;
  color: #848199;
  padding: 10px 0;
}
.subscription-plans-page .plan-card ul li:before {
  content: "";
  font-family: "Font Awesome 5 Free";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #FED51D;
  display: inline-block;
  text-align: center;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  margin-right: 5px;
  line-height: 20px;
}

.magazine-single .magazine-cover {
  width: 100%;
  margin: 10px 0 0;
}
.magazine-single .magazine-cover img {
  width: 100%;
  height: 100%;
}

.magazines-listing {
  display: flex;
  flex-flow: wrap;
  align-items: flex-start;
  justify-content: space-between;
}
.magazines-listing .magazine-card {
  width: 23%;
  max-width: 100%;
  margin: 0 0 40px;
  box-shadow: 0 8px 8px 0 rgba(158, 160, 188, 0.5);
}
.magazines-listing .magazine-card a {
  width: 100%;
}
.magazines-listing .magazine-card figure {
  width: 100%;
  height: 330px;
}
.magazines-listing .magazine-card figure img {
  width: 100%;
  height: 100%;
}

.expert-panel {
  margin: 40px 0;
}
.expert-panel .experts {
  padding: 10px 20px;
  background: #222020;
  margin: 20px 0 0;
}
.expert-panel .expert {
  display: flex;
  margin: 10px 0;
}
.expert-panel .expert figure {
  border: 1px solid #fff;
  width: 56px;
  height: 56px;
}
.expert-panel .expert figure img {
  width: 100%;
  height: 100%;
}
.expert-panel .expert .about-expert {
  color: #fff;
  font-size: 11px;
  line-height: 14px;
  font-weight: 400;
  width: calc(100% - 50px);
  padding-left: 10px;
}
.expert-panel .expert .about-expert .name {
  color: #FFF;
}
.expert-panel .expert .about-expert .details {
  color: #ddd;
  font-size: 9px;
  line-height: 11px;
}

.table-of-contents h2 {
  font-family: Oswald, sans-serif;
  font-size: 48px;
  line-height: 48px;
  color: #000;
  margin: 0 0 35px;
}
.table-of-contents .table-section-title {
  font-weight: bold;
  color: #E1002A;
  margin: 0 0 20px;
  text-transform: uppercase;
}
.table-of-contents .table-section-title.type-2 {
  background: #000;
  color: #FFF;
  padding: 5px 20px;
  display: inline-block;
  text-transform: initial;
}
.table-of-contents .inside-section {
  margin-left: 60px;
}
@media (min-width: 1200px) {
  .table-of-contents .inside-section {
    padding-right: 60px;
  }
}
@media (max-width: 767px) {
  .table-of-contents .inside-section {
    margin-left: 35px;
  }
}
.table-of-contents .associated-article {
  margin: 0 0 20px;
}
.table-of-contents .associated-article .assoc-title {
  font-weight: bold;
  font-size: 16px;
  margin-left: 2px;
  margin-bottom: 1px;
  line-height: 22px;
}
.table-of-contents .associated-article .assoc-description {
  font-size: 14px;
  padding: 5px 0;
  color: #444;
  line-height: 19px;
}
.table-of-contents .associated-article .author_name {
  font-size: 14px;
}
.table-of-contents .associated-article .fa-long-arrow-alt-right {
  font-size: 20px;
  margin: 0 10px 0 -33px;
  font-weight: lighter;
  color: #ccc;
  vertical-align: top;
}

.text-right {
  text-align: right;
}

.editors-column {
  box-shadow: 0px 0px 8px rgba(225, 224, 224, 0.64);
  margin: 0 0 50px;
}
.editors-column .editors-column-head {
  background: #fed51d;
  padding: 20px;
  display: flex;
  border-radius: 8px;
  max-height: 175px;
  overflow: hidden;
  align-items: flex-start;
}
.editors-column .editors-column-head .editors-image {
  width: 100px;
  height: 100px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
.editors-column .editors-column-head .editors-title {
  padding: 0 20px;
}
.editors-column .editors-column-head .editors-title strong {
  font-size: 17px;
  margin: 0 0 10px;
  display: block;
}
.editors-column .editors-column-body {
  text-align: justify;
  padding: 20px;
}
.editors-column .editors-column-body .description-wrap {
  height: 200px;
  overflow: hidden;
}

.row-card {
  margin: 15px 0;
  box-shadow: 0px 0px 8px rgba(225, 224, 224, 0.64);
}
.row-card .row-card-image {
  width: 200px;
  height: 180px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  order: 1;
}
.row-card .row-card-image img {
  width: 100%;
  height: 100%;
}
.row-card .row-card-text {
  width: calc(100% - 200px);
  order: 2;
}
.row-card.image-right .row-card-image {
  order: 2;
}
.row-card.image-right .row-card-text {
  order: 1;
  text-align: right;
}
.row-card .row-card-text {
  padding: 10px 20px;
}
.row-card .row-card-title {
  margin: 0 0 10px;
  font-size: 18px;
  height: 35px;
  overflow: hidden;
}
.row-card .row-card-content {
  height: 92px;
  overflow: hidden;
  margin: 0 0 5px;
  text-align: justify;
  color: #888;
}

.column-actions {
  margin: 15px 0;
  float: left;
}

.editor-page .about-author {
  padding: 20px;
  background: #fed51d;
  display: flex;
  justify-content: flex-start;
  margin: 0 0 15px;
}
.editor-page .author-image {
  width: 150px;
  height: 150px;
  display: inline-block;
  background-size: cover;
  background-position: center center;
}
.editor-page .author-details {
  width: auto;
  padding-left: 20px;
  display: inline-block;
}
.editor-page .author-article {
  line-height: 20px;
}
.editor-page .author-article p {
  margin: 0 0 10px;
}
.editor-page .author-article h4 {
  margin: 0 0 15px;
  font-size: 22px;
}

.magazine-action-btn {
  background: #000;
  color: #fed51d;
  padding: 10px 20px;
}

.term-power-group h1 {
  font-family: Oswald, sans-serif;
  font-size: 48px;
  line-height: 48px;
  color: #000;
  margin: 0 0 35px;
  letter-spacing: -1px;
}
@media (max-width: 767px) {
  .term-power-group h1 {
    margin-top: 40px;
  }
}
.term-power-group h3 {
  font-family: Oswald, sans-serif;
  font-size: 24px;
  line-height: 25px;
  margin: 0 0 10px;
  font-weight: 300;
  letter-spacing: 0.5px;
}
.term-power-group .term-power-list .word {
  font-family: Oswald, sans-serif;
  font-size: 18px;
  line-height: 25px;
  margin: 0 0 10px;
  color: #eb8200;
  font-weight: 300;
  padding: 10px 15px;
  margin: 0;
  letter-spacing: 0.5px;
}
.term-power-group .term-power-list .word.accordion-button::after {
  width: 1rem;
  height: 1rem;
  background-size: 1rem;
}
.term-power-group .term-power-list .word.accordion-button:focus {
  border: 0;
  box-shadow: none;
}
.term-power-group .term-power-list .meaning {
  line-height: 20px;
}

.letters {
  margin-top: 65px;
}
.letters h1 {
  font-family: Oswald, sans-serif;
  font-size: 48px;
  line-height: 48px;
  color: #000;
  margin: 0 0 35px;
  letter-spacing: -1px;
}
.letters .carousel-captionx {
  color: #000;
  padding: 60px 20px 40px;
  background: #f7f7f7;
}
.letters .carousel-captionx h4 {
  font-size: 18px;
  text-align: center;
  color: #E1002A;
}
.letters .carousel-captionx p {
  padding: 10px 0;
  line-height: 19px;
}
.letters .carousel-captionx h5 {
  text-align: right;
  font-size: 16px;
  font-style: italic;
  color: #959595;
}
.letters .carousel-indicators {
  top: 20px;
}
.letters .carousel-indicators [data-bs-target] {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.letters .carousel-indicators .active {
  background-color: #eb8200 !important;
}

footer {
  border-top: 1px solid #eee;
  background: #f9f9f9;
  margin-top: 25px;
}
footer .link-card-title {
  font-weight: bold;
  color: #333 !important;
  margin-bottom: 15px;
}
@media (max-width: 575.98px) {
  footer .link-card {
    width: 100%;
    margin: 0 0 20px;
  }
}
footer .link-card ul li {
  padding: 5px 0;
  font-family: Poppins, sans-serif;
}

.subscribe-section p:not(.copyright_text) {
  font-size: 18px;
}
.subscribe-section h4 {
  font-weight: 400;
  margin: 25px 0 50px;
}
@media (max-width: 575.98px) {
  .subscribe-section h4 {
    margin: 30px;
  }
}
.subscribe-section .footer-links {
  padding: 50px 150px;
}
@media (max-width: 575.98px) {
  .subscribe-section .footer-links {
    flex-wrap: wrap;
    padding: 20px;
  }
  .subscribe-section .footer-links li {
    width: 100%;
    padding: 5px 0;
  }
}

@media (max-width: 767px) {
  .slidemenu.mobile {
    top: 75px;
    height: calc(100vh - 73px);
    overflow-y: scroll;
    overflow-x: hidden;
    left: -110vw;
  }
  .slidemenu.mobile.show {
    left: 0;
  }
  .slidemenu.mobile .container {
    flex-flow: column;
  }
  .slidemenu.mobile ul {
    padding: 0 0 10px;
    border-bottom: 1px solid #ededed;
    width: 100%;
    margin: 0 0 10px;
  }
  .slidemenu.mobile ul li.parent-link a {
    padding: 5px 0 5px;
  }
  .slidemenu.mobile ul li a {
    padding: 3px 0;
  }

  .section-more-articles {
    margin: 0 0 40px;
  }
  .section-more-articles.category-related-articles {
    padding: 0;
  }
  .section-more-articles .article-card figure img {
    width: 100%;
  }
  .section-more-articles .impact-articles {
    flex-flow: column;
  }
  .section-more-articles .impact-articles .impact-article-card {
    padding: 20px 0;
    width: 100%;
    border-bottom: 1px solid #f3f3f3;
  }
  .section-more-articles .impact-articles .impact-article-card:last-of-type {
    border-bottom: 0;
  }

  .category-articles .article-card {
    flex-flow: column;
  }
  .category-articles .article-card > figure {
    order: 1;
    width: 100% !important;
    margin: 0 0 10px !important;
  }
  .category-articles .article-card > div {
    order: 2;
    width: 100% !important;
    padding: 0 !important;
  }

  .article-page, .category-page {
    padding: 40px 0;
  }
  .article-page .article-actions, .category-page .article-actions {
    flex-flow: column;
    align-items: flex-start;
  }
  .article-page .article-actions .article-cats, .category-page .article-actions .article-cats {
    margin: 0 0 20px;
  }
  .article-page .article-description h5, .article-page .article-description h6, .category-page .article-description h5, .category-page .article-description h6 {
    font-size: 13px;
    line-height: 18px;
    padding: 0 20px;
  }
}