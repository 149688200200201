.Web-form {
  font-family: Lora, sans-serif;
  font-size: 14px;

  label {
    margin: 0 0 10px;
  }

  .form-control {
    border: 1px solid #000;
    padding: 12px 20px;
    border-radius: 8px;
    box-shadow: none;

    &.is-invalid {
      border-color: #dc3545;
    }
  }

  .form-group {
    margin: 0 0 30px;
  }

}

.alert.alert-error {
  font-family: Poppins, sans-serif;
  padding: 5px 10px;
  background: transparent;
  color: red;
  border: 0;
  font-size: 12px;
  letter-spacing: 1px;
}

.form-check {

  label {
    font-family: Lora, sans-serif;
    text-transform: capitalize;
  }

  .form-check-input {
    width: 20px;
    height: 20px;
    margin-top: -1px;
    margin-right: 8px;

    &:checked {
      background-color: #fed51d;
      border-color: #fed51d;
    }

    &:focus {
      border-color: #fed51d;
      box-shadow: none;
    }
  }
}

.article-tag-container {
  margin: 5px 0;
}
.article-tag {
  font-size: 12px;
  font-family: 'Oswald';
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 2px;
  cursor: pointer;
}
.article-tag ion-icon {
  font-size: 16px;
  margin-top: -4px;
}

.tox.tox-tinymce {
  border: 1px solid #000;
  border-radius: 8px;
}

.char-counter {
  color: #aaa;
}
