.shop-single-item-page {

  .shop-item-images {
    figure {
      width: 100%;
      height: 400px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .button-group {
      margin: 40px 0;
    }
    button {
      width: 100%;
      margin: 5px 0;
    }

    .other-images {
      display: flex;
      justify-content: space-between;
      img {
        width: 56px;
        height: 80px;
      }
    }
  }

  .shop-item-details {

    .single-title {
      font-weight: 700;
      font-size: 70px;
    }

    .single-author {
      text-transform: uppercase;
    }

    .single-issn-volume {
      font-size: 21px;
      margin: 30px 0;

      span{
        display: inline-block;

        &:first-of-type {
          margin-right: 50px;
        }
      }
    }

    .single-price-block {

      h2 {
        display: inline-block;
        margin-right: 20px;
      }

      .discounted_price {
        font-weight: 700;
        font-size: 48px;
      }

      .original_price {
        font-size: 36px;
        span {
          text-decoration: line-through;
        }
      }

      .taxes {
        color: #0B8C56;
        font-size: 14px;
        text-transform: uppercase;
      }
    }

    .single-quantity {
      margin: 40px 0;
      font-size: 36px;
      font-weight: 600;
      color: #0B8C56;

      .quantity {
        color: initial;
        display: inline-block;
        margin-right: 80px;

        input {
          width: 70px;
          border-radius: 4px;
          font-size: 16px;
          padding: 12px 10px;
          border: 1px solid;
          margin: 0 10px;
        }
      }
    }

    .single-description {}

    .extra-actions {
      margin: 10px 0;
    }
  }

  .single-product-extra-details {
    margin: 50px 0;

    ul {
      li {
        display: inline-block;
        width: 33%;
        font-size: 21px;
        font-family: Oswald, sans-serif;
        margin: 15px 0;

        span {
          font-family: Poppins, sans-serif;
          margin-left: 20px;
        }
      }
    }
  }
}

.user-reviews-section {

  .add-review {
    h3 {
      font-size: 21px;
      font-weight: 600;
    }

    .review-comment {
      margin: 50px 0;

      .form-control {
        background: #FFFFFF;
        box-shadow: inset 4px 4px 10px rgba(0, 0, 0, 0.25);
        border-radius: 12px;
        padding: 20px;
      }

      label {
        margin: 20px 0 10px;
      }

      button {
        margin-top: 20px;
      }
    }
  }

  .reviews {
    padding-left: 100px;

    .review-block {
      margin: 0 0 50px;

      .user-name-image {
        font-size: 18px;
        font-weight: 500;
      }

      .user-comment-title {
        font-size: 21px;
        font-weight: 600;
      }

      .user-comment {
        margin: 20px 0;
      }

      .review-actions {

        a {
          padding: 0;
          border-radius: 5px;
          width: 132px;
          height: 40px;
          text-align: center;
          line-height: 38px;

          &:first-of-type {
            border: 1px solid #ccc;
          }
        }
      }
    }
  }
}
