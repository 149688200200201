.hero-blocks {
  $block_width: 20%;
  @media (max-width: 575.98px) {
    $block_width: 100%;
  }

  .block {
    width: $block_width;

    &:nth-of-type(2) {
      width: 3 * $block_width;
      margin: 0 30px;

      @media (max-width: 575.98px) {
        width: $block_width;
        margin: 0 0 20px;
      }
    }
    @media (max-width: 575.98px) {
      width: $block_width;
      margin: 0 0 20px;
    }
  }
}

.news_title {
  font-size: 20px;
  line-height: 26px;
  font-weight: 600;
}

// Recent section
.recent_news_block {
  padding: 40px 20px;

  .recent_news_item {
    margin: 15px 0;
  }

  @media (max-width: 575.98px) {
    .recent_news_title { font-size: 16px; }
  }

  .news_image { 
    margin: 0 0 10px; 
    height: 145px;
    overflow: hidden;
  }
}

.highlight_news {
  padding: 40px;

  .item {

    .item_tag {
      font-weight: 700;
      @media (max-width: 575.98px) {
        font-size: 14px;
        line-height: 18px;
      }
    }
    .item_title {
      font-size: 48px;
      font-weight: 700;
      line-height: 72px;

      @media (max-width: 575.98px) {
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 10px;
      }
    }
    .item_description {
      line-height: 24px;
      font-size: 16px;
      @media (max-width: 575.98px) {
        height: 70px;
        overflow: hidden;
      }
    }
    .item_author {
      padding: 20px 0;
    }

    .item_image {
      width: 100%;
    }
  }
}

.category_news {

  .category_news_item {
    border-bottom: 1px solid #ccc;
    padding: 20px 0;

    @media (max-width: 575.98px) {
      padding: 10px;
    }

    &:first-of-type { padding-top: 0; }
    &:last-of-type { border-bottom: 0; }
  }

  .news_title {
    margin: 5px 0 10px;
    display: block;
  }
  .author { padding: 5px 0; }
}
