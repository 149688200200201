.ad-space {
  border: 1px solid $border_nav_color;
  padding: 40px 0;

  p {
    font-size: 21px;
    line-height: 28px;
    @media (max-width: 575.98px) {
      font-size: 14px;
      line-height: 20px;
    }
  }

  h2 {
    font-size: 64px;
    line-height: 100px;
    @media (max-width: 575.98px) {
      font-size: 36px;
      line-height: 64px;
    }
  }
}
.ad-placement {
  width: 100%;
  height: 100%;
  background-color: $bg_light_grey;
}
