@media (max-width: 575.98px) {
  .books-and-articles {
    margin: 0 -15px;
  }
}

.books-section {
  padding: 40px;
  width: 40%;
  @media (max-width: 575.98px) {
    width: 100%;
    padding: 20px;
  }

  .read_more_btn_wrap {
    margin-top: 30px;
  }
}
.book-card {
  padding: 20px;
  border: 1px solid #000;
  margin: 15px 0;
  @media (max-width: 575.98px) {
    padding: 10px;
  }

  &:last-of-type { margin-bottom: 0; }

  .book-image {
    width: 50%;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .book-details {
    width: 50%;
    padding: 0 20px;
    @media (max-width: 575.98px) {
      padding: 0 0 0 15px;
    }
  }
  .book-title {
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
    @media (max-width: 575.98px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
  .book-description {
    padding: 5px 0 0;

    &:last-of-type { padding-bottom: 10px; }

    @media (max-width: 575.98px) {
      height: 90px;
      overflow: hidden;
      padding: 0;
      margin: 5px 0 10px;
    }
  }
  .btn {
    padding: 5px 20px;
    @media (max-width: 575.98px) {
      padding: 5px 15px;
      font-size: 14px;
    }
  }
}

.read_more_btn_wrap {

  &.position-absolute {
    bottom: 40px;
    right: 40px;
  }
}
