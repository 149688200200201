body {
  font-family: "PT Serif", serif;
  // font-family: Poppins, sans-serif;
  font-weight: 400;
  font-size: $body_fz;
  color: $text_black;
  line-height: 18px;
  background-color: $bg_white;
}

img {
  max-width: 100%;
}

figure, p { margin: 0; }

h1, h2, h3, h4, h5, h6 { margin: 0; }

.b0 {bottom: 0 !important;}

.text-red { color: $text_red !important; }
.text-grey { color: $text_grey !important; }
.text-light-grey { color: #9b9b9b !important; }
.text-white { color: $text_white !important; }
.text-light-white { color: $text_light_white !important; }
.text-primary { color: $text_primary !important; }

.bg-yellow {background-color: $bg_yellow !important;}
.bg-black {background-color: $bg_black !important;}
.bg-white {background-color: $bg_white !important;}

.font-poppins { font-family: Poppins, sans-serif !important; }
.font-oswald { font-family: Oswald, sans-serif !important; }
.font-lora { font-family: Lora, sans-serif !important; }

.clearfix { clear: both; }

.has-border-radius { border-radius: 12px; }
.border-0 {border: 0;}
.no-middle {vertical-align: unset;}

.pagination {
  justify-content: center; 
  margin: 40px 0 0;

  .page-item {

    &.disabled {
      cursor: not-allowed;
    }

    .page-link {
      color: #fed51d;
    }

    

    &.active {

      .page-link {
        
        background-color: #000;
        border-color: #000;
      }
    }

    &:first-of-type, &:last-of-type {
      .page-link {
        color: #000;
        font-size: 22px;
      }
    }
  }
}


a {
  text-decoration: none;
  color: $text_black;
  display: inline-block;

  &:hover {
    text-decoration: underline;
    color: $text_red;
  }
}

h4, .h4 {
  font-size: 24px;
  line-height: 32px;
  @media (max-width: 575.98px) {
    font-size: 20px;
    line-height: 28px;
  }
}

h5 {
  font-size: 18px;
  line-height: 24px;

}

.spacing-around {
  $padding: 50px;

  padding-top: $padding;
  padding-bottom: $padding;
  font-size: inherit;

  &.small {
    padding-top: $padding / 2;
    padding-bottom: $padding / 2;
  }

  &.large {
    padding-top: $padding * 2;
    padding-bottom: $padding * 2;
  }
}


.section-title {
  padding: 20px 0;
  border: 1px solid $border_light_color;
  border-width: 1px 0;

  h2 {
    font-size: 48px;
    line-height: 48px;
    text-transform: uppercase;
    margin-bottom: 15px;
    @media (max-width: 575.98px) {
      font-size: 28px;
      line-height: 28px;
    }
  }
}


.owl-carousel {

  .owl-dots {
    text-align: center;

    .owl-dot {
      margin: 0 2px;

      span {
        display: inline-block;
        background-color: $bg_black;
        width: 8px;
        height: 8px;
        border-radius: 50%;
      }

      &.active {
        span {
          background-color: $text_red;
          width: 12px;
          height: 8px;
          border-radius: 5px;
        }
      }
    }
  }
}

.ad-space-full {
  padding: 20px 10px;
  margin: 0 0 20px;
  border: 1px solid $border_light_color;
  border-width: 1px 0;
}

.pr-0 {
  padding-right: 0 !important;
}

.divider {
  border-top: 1px solid #CDCDCD;
  margin: 10px 0 30px;
}

.subscription-renew-alert {
  font-size: 14px;
  font-family: Lora,sans-serif;
  letter-spacing: 0.5px;
  margin: 10px 0;
  padding: 10px 25px;

  .fas {
    font-size: 20px;
    margin: 0 5px 0 0;
  }
}
.alert-link {
  vertical-align: baseline;
}

.sharethis-inline-share-buttons {z-index: 1 !important;}

