.magazine-single {

    .magazine-cover {
        width: 100%;
        margin: 10px 0 0;
        img {
            width: 100%;
            height: 100%;
        }
    }
    
}

.magazines-listing {
    display: flex;
    flex-flow: wrap;
    align-items: flex-start;
    justify-content: space-between;

    .magazine-card {
        width: 23%;
        max-width: 100%;
        margin: 0 0 40px;
        box-shadow: 0 8px 8px 0 rgb(158 160 188 / 50%);

        a {
            width: 100%;
        }

        figure {
            width: 100%;
            height: 330px;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }
    
}

.expert-panel {
    margin: 40px 0;

    .experts {
        padding: 10px 20px;
        background: #222020;
        margin: 20px 0 0;
    }

    .expert {
        display: flex;
        margin: 10px 0;

        figure {
            border: 1px solid #fff;
            width: 56px;
            height: 56px;
            img {
                width: 100%;
                height: 100%;
            }
        }

        .about-expert {
            color: #fff;
            font-size: 11px;
            line-height: 14px;
            font-weight: 400;
            width: calc(100% - 50px);
            padding-left: 10px;

            .name {color: #FFF;}
            
            .details {
                color: #ddd;
                font-size: 9px;
                line-height: 11px;
            }
        }
    }
}

.table-of-contents {
    
    h2 {
        font-family: Oswald, sans-serif;
        font-size: 48px;
        line-height: 48px;
        color: #000;
        margin: 0 0 35px;
    }

    .table-section-title {
        font-weight: bold;
        color: #E1002A;
        margin: 0 0 20px;
        text-transform: uppercase;

        &.type-2 {
            background: #000;
            color: #FFF;
            padding: 5px 20px;
            display: inline-block;
            text-transform: initial;
        }
    }

    .inside-magazine {
        // margin-left: 30px;
    }

    .inside-section {
        margin-left: 60px;

        @media (min-width: 1200px) {
            padding-right: 60px;
        }

        @media (max-width: 767px) {
            margin-left: 35px;
        }
    }

    .associated-article {
        margin: 0 0 20px;

        .assoc-title {
            font-weight: bold;
            font-size: 16px;
            margin-left: 2px;
            margin-bottom: 1px;
            line-height: 22px;
        }

        .assoc-description {
            font-size: 14px;
            padding: 5px 0;
            color: #444;
            line-height: 19px;
        }

        .author_name {font-size: 14px;}

        .fa-long-arrow-alt-right {
            font-size: 20px;
            margin: 0 10px 0 -33px;
            font-weight: lighter;
            color: #ccc;
            vertical-align: top;
        }
    }
}

.text-right {text-align: right;}

.editors-column {
    box-shadow: 0px 0px 8px rgb(225 224 224 / 64%);
    margin: 0 0 50px;

    .editors-column-head {
        background: #fed51d;
        padding: 20px;
        display: flex;
        border-radius: 8px;
        max-height: 175px;
        overflow: hidden;
        align-items: flex-start;

        .editors-image {
            width: 100px;
            height: 100px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
        }

        .editors-title {
            padding: 0 20px;

            strong {
                font-size: 17px;
                margin: 0 0 10px;
                display: block;
            }
        }
    }

    .editors-column-body {
        text-align: justify;
        padding: 20px;

        .description-wrap {
            height: 200px;
            overflow: hidden;   
        }
    }
}

.row-card {
    margin: 15px 0;
    box-shadow: 0px 0px 8px rgb(225 224 224 / 64%);

    .row-card-image {
        width: 200px;
        height: 180px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        order: 1;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .row-card-text {
        width: calc(100% - 200px);
        order: 2;
    }

    &.image-right {
        .row-card-image {
            order: 2;
        }
    
        .row-card-text {
            order: 1;
            text-align: right;
        }
    }

    .row-card-text {
        padding: 10px 20px;
    }

    .row-card-title {
        margin: 0 0 10px;
        font-size: 18px;
        height: 35px;
        overflow: hidden;
    }

    .row-card-content {
        height: 92px;
        overflow: hidden;
        margin: 0 0 5px;
        text-align: justify;
        color: #888;
    }
}

.column-actions {
    margin: 15px 0;
    float: left;
}

.editor-page {

    .about-author {
        padding: 20px;
        background: #fed51d;
        display: flex;
        justify-content: flex-start;
        margin: 0 0 15px;
    }

    .author-image {
        width: 150px;
        height: 150px;
        display: inline-block;
        background-size: cover;
        background-position: center center;
    }

    .author-details {
        width: auto;
        padding-left: 20px;
        display: inline-block;
    }

    .author-article {
        line-height: 20px;

        p {
            margin: 0 0 10px;
        }

        h4 {
            margin: 0 0 15px;
            font-size: 22px;
        }
    }
}

.magazine-action-btn {
    background: #000;
    color: #fed51d;
    padding: 10px 20px;
}

.term-power-group {

    h1 {
        font-family: Oswald, sans-serif;
        font-size: 48px;
        line-height: 48px;
        color: #000;
        margin: 0 0 35px;
        letter-spacing: -1px;

        @media (max-width: 767px) {
            margin-top: 40px;
        }
    }

    h3 {
        font-family: Oswald, sans-serif;
        font-size: 24px;
        line-height: 25px;
        margin: 0 0 10px;
        font-weight: 300;
        letter-spacing: 0.5px;
    }
    
    .term-power-list {
        
        .word {
            font-family: Oswald, sans-serif;
            font-size: 18px;
            line-height: 25px;
            margin: 0 0 10px;
            color: #eb8200;
            font-weight: 300;
            padding: 10px 15px;
            margin: 0;
            letter-spacing: 0.5px;

            &.accordion-button {
                &::after {
                    width: 1rem;
                    height: 1rem;
                    background-size: 1rem;
                }

                &:focus {
                    border: 0;
                    box-shadow: none;
                }
            }


        }
        .meaning {
            line-height: 20px;
        }
    }
}

.letters {
    margin-top: 65px;

    h1 {
        font-family: Oswald, sans-serif;
        font-size: 48px;
        line-height: 48px;
        color: #000;
        margin: 0 0 35px;
        letter-spacing: -1px;
    }

    .carousel-captionx {
        color: #000;
        padding: 60px 20px 40px;
        background: #f7f7f7;

        h4 {
            font-size: 18px;
            text-align: center;
            color: #E1002A;
        }

        p {
            padding: 10px 0;
            line-height: 19px;
        }

        h5 {
            text-align: right;
            font-size: 16px;
            font-style: italic;
            color: #959595;
        }
    }

    .carousel-indicators {
        top: 20px;

        [data-bs-target] {
            width: 8px;
            height: 8px;
            border-radius: 50%;
        }

        .active {
            background-color: #eb8200 !important;
        }
    } 

    
}