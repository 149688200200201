.article-head {

  .authors {
    line-height: 36px;
    font-size: 18px;
  }

  .article_publish_date {
    line-height: 24px;
    font-size: 17px;
  }
}

.fancybox__figure__name, .fancybox__caption {
  font-size: 18px;
  margin: 10px 0 20px;
  text-align: center;
}

.article-page, .category-page {
  padding-right: 80px;

  .article-title {
    font-weight: 700;
    font-size: 40px;
  }

  .article-author {
    font-weight: 500;
    padding: 5px 0 5px;
    span {
      font-weight: 300;
    }
  }

  .article_abstract {
    line-height: 28px;
    font-size: 18px;
    font-family: 'PT Serif', serif;
  }

  .about_author {
    font-weight: 600;
    line-height: 28px;
    font-size: 18px;
    font-family: 'PT Serif', serif;
  }

  .article-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 15px;

    .social-actions {

      li {
        display: inline-block;

        a {
          font-size: 20px;
          background: #000;
          color: #fff;
          padding: 12px;
          border-radius: 50%;
          margin: 0 5px;

          &:first-of-type {
            margin-left: 0;
          }
        }
      }
    }

    .other-actions {

      .btn.btn-primary {
        border: 0;
        color: $color__secondary;


        &.btn-download {
          background-color: $color__primary;

          ion-icon {
            font-size: 22px;
            vertical-align: -3px;
            margin: 0 5px;
          }
        }
      }
    }

  }

  .article-featured-image {
    margin: 0 0 15px;
    img {
      width: 100%;
    }
  }

  .other-article-details {
    margin: 15px 0;

    ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;

      p {
        font-family: Oswald, sans-serif;
        font-weight: 400;
      }
    }
  }

  .article-description {
    

    p {
      padding: 0 0 15px;
      line-height: 28px;
      font-size: 18px;
      font-family: 'PT Serif', serif;

      a {
        color: #0d6efd;
      }
    }

    h4 + p {
      padding: 0 !important;
    }

    figure.image {
      margin: 20px 0;
      text-align: center !important;

      figcaption {
        width: 90%;
        margin: 0 auto;
        font-size: 18px;
        
        h6 {
          font-size: 16px;
        }
      }
    }

    .article_publish_date {
      strong {
        display: inline-block;
        margin: -3px 0 0;
      }
    }

    h4 {
      font-weight: bold;
      margin-bottom: 15px;
      margin-top: 20px;
    }

    h5 {
      font-size: 16px;
      font-weight: bold;
    }

    ul, ol {
      margin: 0 0 1rem;
      padding-left: 2rem;
      list-style: initial;

      li {
        line-height: 28px;
        font-size: 18px;
        position: relative;
      }
    }

    ol {
      list-style: decimal;
    }

    img.full-width-image {
      width: 90%;
      height: auto;
      margin: 0 0 5px;
    }

    .article_references {

      a {
        color: initial;
        // text-decoration: underline;
      }

      p {
        position: relative;
      }

      .g-anchor {
        position: absolute;
        top: 0;
      }

    } 
  }

}

.section-more-articles {

  &.category-related-articles {
    margin-top: 40px;
    padding: 0 80px 0 0;
  }

  .more-articles-title {
    font-family: Oswald, sans-serif;
    font-size: 40px;
    line-height: 48px;
    font-weight: 400;
    margin-top: 8px;
  }

  .article-card {
    padding: 15px 0;
    border-bottom: 1px solid #f3f3f3;

    &.impact-article-card {
      padding: 20px;
      border: 0;
      width: 25%;

      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        padding-right: 0;
      }

      h4.title {
        height: 72px;
        overflow: hidden;
        margin: 0 0 12px;
      }
    }

    &:first-of-type {
        padding-top: 25px;
    }

    &:last-of-type {
      border-bottom: 0;
    }

    figure {
      margin: 0px 0 15px;
    }

    .tag {
      margin: 0 0 5px;
    }

    .title {
      font-size: 20px;
      line-height: 24px;
      margin: 5px 0;
    }

    .description {
      font-family: 'Lora';
      color: $color__secondary;
      margin: 10px 0;
    }

    .author {

      em {
        display: inline-block;
        font-weight: 300;
        color: #666;
        margin: -2px 3px 0 0;
      }
    }
  }
}

.recommended-books {
  margin: 30px 0;

  .rb-card {
    padding: 30px;
    background: #FFFFFF;
    box-shadow: 1.03103px 4.12414px 15px rgb(130 130 130 / 18%);
    border-radius: 10px;
    margin: 0 5px;

    img {
      width: 185px;
      height: 205px;
    }

    h5 {
      font-size: 14px;
      color: #333;
      line-height: 21px;
      margin: 15px 0;
    }

    a.add-to-cart-link {
      display: block;
      margin: 0 0 5px;
    }

    a.buy-now-btn {
      font-size: 14px;
      padding: 5px 15px;
      border-radius: 10px;
    }
  }
}
