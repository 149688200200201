.editors-pick {
  margin: 20px 0 0;
  padding: 60px 0 40px;
}
.editors-pick-title-wrap {
  position: relative;
  margin-bottom: 20px;

  .editors-pick-title {
    font-size: 70px;
    font-weight: bold;
    @media (max-width: 575.98px) {
      font-size: 32px;
      padding: 0 50px;
    }
  }
  .quote {
    width: 64px;
    position: absolute;
    @media (max-width: 575.98px) {
      width: 25px;
    }

    &.quote-left {
      left: -75px;
      top: -20px;
      @media (max-width: 575.98px) {
        left: 20px;
        top: -10px;
      }
    }
    &.quote-right {
      right: -75px;
      bottom: -20px;
      @media (max-width: 575.98px) {
        right: 20px;
        bottom: -10px;
      }
    }
  }
}
.editors-pick-articles {
  justify-content: center;
  @media (max-width: 575.98px) {
    width: 100%;
    justify-content: space-around;
  }

  .pick-article {
    margin: 20px;
    width: 18%;
    @media (max-width: 575.98px) {
      margin: 15px 0 0;
      width: 43%;
    }

    img {
      width: 240px;
      height: 160px;
    }

    .description { padding: 10px 0; }
    .author { font-size: 10px; }
  }
}
