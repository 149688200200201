/* lora-regular - latin */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/Lora/lora-v17-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../fonts/Lora/lora-v17-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../fonts/Lora/lora-v17-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../fonts/Lora/lora-v17-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../../fonts/Lora/lora-v17-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../fonts/Lora/lora-v17-latin-regular.svg#Lora') format('svg'); /* Legacy iOS */
}
/* lora-600 - latin */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 600;
  src: url('../../fonts/Lora/lora-v20-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../../fonts/Lora/lora-v20-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/Lora/lora-v20-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/Lora/lora-v20-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/Lora/lora-v20-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/Lora/lora-v20-latin-600.svg#Lora') format('svg'); /* Legacy iOS */
}
/* lora-500 - latin */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 500;
  src: url('../../fonts/Lora/lora-v20-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../../fonts/Lora/lora-v20-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/Lora/lora-v20-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/Lora/lora-v20-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/Lora/lora-v20-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/Lora/lora-v20-latin-500.svg#Lora') format('svg'); /* Legacy iOS */
}
