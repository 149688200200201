.owl-carousel .owl-nav.disabled {
  display: block;
}

.shopBannerCarousel {

  .owl-item {
    background-color: $color__secondary;
  }

  .carousel-control-next, .carousel-control-prev {
    opacity: 1;
    font-size: 48px;
  }

  &.owl-theme .owl-nav {
    top: 50%;
    position: absolute;
    color: #fff;
    font-size: 140px;
    margin-top: -30px;
    width: 100%;

    button {
      position: absolute;

      &.owl-prev {
        left: 40px;
      }

      &.owl-next {
        right: 40px;
      }

      &:focus, &:hover {
        background: transparent;
      }
    }
  }

  .item {
    width: 100%;
    max-width: 100%;
    padding: 30px 120px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .item-image {
    width: 210px;
    height: 300px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .item-description {
    width: calc(100% - 280px);
  }
  .item-title {
    color: #fff;
    font-size: 48px;
  }
  .item-price {
    color: $text_primary;
    font-size: 24px;
    margin: 40px 0 10px;
  }
}

.owl-carousel-wrapper {
  margin: 50px;
}
.carousel-wrapper-head {
  margin: 0 -50px 50px;

  .carousel-wrapper-title {
    font-family: Oswald, sans-serif;
    font-size: 48px;
  }
}
.shopCarousel {

  .item {
    text-align: center;
  }
  .item-image {
    width: 140px;
    height: 200px;
    margin: 0 auto;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .item-title {
    font-size: 21px;
    padding: 15px 30px;
    height: 85px;
    overflow: hidden;
  }

  .item-author {
    font-size: 14px;
    font-weight: 400;
  }

  &.owl-theme .owl-nav {
    top: 30%;
    position: absolute;
    width: 100%;
    font-size: 70px;

    button {
      position: absolute;

      &:hover {
        background: 0 0;
        color: #000;
      }
    }

    button.owl-prev {
      left: -40px;
    }

    button.owl-next {
      right: -40px;
    }
  }
}

// Books and Magazines shop section
.shop-filters {
  width: 250px;

  .filters-head {
    margin: 20px 0;
  }

  .filters {

    .form-group {
      margin: 0 0 20px;

      label {
        font-size: 21px;
        text-transform: capitalize;
        font-weight: 600;
        line-height: 24px;
        margin: 0 0 15px;

        &.form-check-label {
          font-size: 14px;
          font-family: Oswald, sans-serif;
          text-transform: uppercase;
          font-weight: normal;
          margin: 0 0 2px;
          color: $text_red;
        }
      }

      .form-check-input {
        margin-top: 0;
        margin-right: 10px;
      }
    }
  }
}


.shop-books-and-magazines {
  width: calc(100% - 300px);
  flex-wrap: wrap;
  place-self: flex-start;

  .shop-item {
    width: 33%;
    margin: 20px 0;

    .item-description {
      padding-right: 20px;
    }

    .shop-item-title {
      font-size: 21px;
      line-height: 28px;
      height: 56px;
      overflow: hidden;
    }

    .shop-item-author {
      font-size: 12px;
      margin: 0 0 30px;
      height: 20px;
      overflow: hidden;
    }

    .shop-item-price {
      font-size: 12px;
    }

    .simple-link {
      font-size: 12px;
      display: block;
      padding: 5px 0;
      text-decoration: none;
    }

    .btn {
      font-size: 12px;
      padding: 5px 15px;
    }
  }

  .item-image {
    width: 125px;
    height: 180px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .item-description {
    width: calc(100% - 150px);
  }

}
