#subscribe {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  padding: 60px;
  background: #fff;
  width: 100%;
  -webkit-box-shadow: 0px -10px 13px 0px rgba(255, 255, 255, 1);
  -moz-box-shadow:    0px -10px 13px 0px rgba(255, 255, 255, 1);
  box-shadow:         0px -10px 13px 0px rgba(255, 255, 255, 1);

  h2 {
    font-family: Oswald, sans-serif;
    font-size: 48px;
  }

  h4 {
    font-size: 21px;
    padding: 15px 0 25px;
  }

  .or {
    font-size: 21px;
    font-weight: 600;
    margin: 25px 0;
  }

  .paid-subscriptions {

    p {
      font-size: 21px;
      font-family: Oswald, sans-serif;
      margin: 0 0 20px;
    }

    .packages {
      display: flex;

      .package {
        border: 0.5px solid #000000;
        border-radius: 12px;
        padding: 15px;
        text-align: left;
        width: 200px;
        margin: 5px;

        .package-tag {
          font-size: 12px;
          font-family: Poppins, sans-serif;
          display: inline-block;
          background: #000;
          color: yellow;
          padding: 5px 15px;
          border-radius: 20px;
          font-weight: 900;
        }

        .package-cost {
          font-family: Poppins, sans-serif;
          font-size: 24px;
          font-weight: 700;
        }

        .package-description {
          font-size: 16px;
          font-family: Poppins, sans-serif;
          margin: 15px 0 10px;
        }

        .choose-package-btn {
          padding: 8px;
          font-size: 14px;
          width: 100%;
          border-radius: 20px;
          text-transform: initial;
        }
      }
    }
  }

  .free-subscripitons {
    border: 2px solid #ccc;
    width: 570px;
    border-radius: 12px;
    padding: 10px 0 25px;

    h4 {
      font-family: Poppins, sans-serif;
      font-weight: 400;
      font-size: 24px;
      line-height: 28px;
      padding: 10px 0 20px;
    }

    .create-account-btn {
      background: #fff;
      border: 1px solid;
      padding: 10px 70px;
      border-radius: 25px;
      text-transform: initial;
    }
  }

}
