$button_border_radius: 10px;

.btn {
  font-size: 18px;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  padding: 10px 38px;
  border-radius: $button_border_radius;

  &.btn-large {
    padding: 12px 38px;
  }

  &.style-1 {
    background-color: $bg_yellow;
    color: $text_black;
  }

  &.style-2 {
    background-color: $bg_black;
    color: $text_primary;
  }

  &.style-3 {
    background-color: #FFFFFF;
    color: $text_primary;
    border: 2px solid $text_primary;
  }
}

button {

  &.is-icon {
    border: 0;
  }
}

.read_more_link {
  font-family: Oswald, sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  text-transform: uppercase;
}

.simple-link {
  color: #0d6efd;
  text-decoration: underline;
}
