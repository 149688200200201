.User-dashboard {

  aside, main {
    background: #FFFFFF;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
    min-height: 100vh;
  }

  .title {
    font-size: 21px;
    line-height: 24px;
    font-weight: 600;
    margin: 0 0 50px;
  }

  .User-dashboard-sidebar {
    padding: 50px 30px;

    .sidebar-links {
      margin: 50px -30px 0;
    }

    .sidebar-link {
      width: 100%;
    }

    .sidebar-link-item {
      font-size: 14px;
      font-family: oswald, sans-serif;
      letter-spacing: 1px;
      padding: 15px 30px;
      width: 100%;
      border: 0;
      text-align: left;
      border-radius: 0;
      color: $text_black;

      &:hover, &.active {
        background-color: $color__primary;
        text-decoration: none;
        color: $text_red;
      }

      ion-icon {
        font-size: 22px;
        margin: -3px 10px 0 0;
      }
    }
  }


  .User-dashboard-main {
    padding: 50px;
  }

  .Web-form {
    .col-md-6 {
      &:nth-of-type(2n+1) {
        padding-right: 30px;
      }

      &:nth-of-type(2n+2) {
        padding-left: 30px;
      }
    }

    .form-group {
      margin: 20px 0;

    }
  }

}
