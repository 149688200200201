@media (max-width: 767px) {

    .slidemenu.mobile {
        top: 75px;
        height: calc(100vh - 73px);
        overflow-y: scroll;
        overflow-x: hidden;
        left: -110vw;

        &.show {
            left: 0;
        }

        .container {
            flex-flow: column;
        }

        ul {
            padding: 0 0 10px;
            border-bottom: 1px solid #ededed;
            width: 100%;
            margin: 0 0 10px;

            li {
                &.parent-link {

                    a {
                        padding: 5px 0 5px;
                    }
                }

                a {padding: 3px 0;}
            }
        }
    }

    .section-more-articles{
        margin: 0 0 40px;

        &.category-related-articles {
            padding: 0;
        }

        .article-card {
            
            figure {
                img {
                    width: 100%;
                }
            }

            
        }

        .impact-articles {
            flex-flow: column;

            .impact-article-card {
                padding: 20px 0;
                width: 100%;
                border-bottom: 1px solid #f3f3f3;

                &:last-of-type {
                    border-bottom: 0;
                }
            }


        }
    }

    .category-articles .article-card {
        flex-flow: column;

        & > figure {
            order: 1;
            width: 100% !important;
            margin: 0 0 10px !important;
        }

        & > div {
            order: 2;
            width: 100% !important;
            padding: 0 !important;
        }
    }
    
    
    .article-page, .category-page {
        padding: 40px 0;

        .article-actions {
            flex-flow: column;
            align-items: flex-start;

            .article-cats {
                margin: 0 0 20px;
            }
        }

        .article-description {
            h5, h6 {
                font-size: 13px;
                line-height: 18px;
                padding: 0 20px;
            }
        }
    }
}