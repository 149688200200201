.cart-page {
  margin: 40px 0;

  h3 {}

  .cart-items {
    width: 100%;
  }

  tr.cart-item {
    background: #FFFFFF;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);

    &.cart-items-head {
      box-shadow: none;
    }

    th, td {
      font-size: 16px;
      line-height: 27px;
      font-weight: normal;
      color: #686868;
      text-transform: uppercase;
      padding: 25px 4px;
      text-align: center;

      &:first-of-type {
        padding-left: 25px;
        text-align: left;
      }
    }

    .item-description {
      position: relative;
      min-height: 100px;

      figure {
        width: 84px;
        height: 120px;
        float: left;
        margin-right: 40px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .item-details {
        float: left;

        h4 {
          font-family: Oswald, sans-serif;
          font-size: 21px;
          line-height: 24px;
          margin-bottom: 3px;
        }

        p {
          font-size: 18px;
          font-weight: normal;
          text-transform: initial;
        }

        .actions {
          position: absolute;
          bottom: 0;
          left: 124px;

          .cart-item-action {
            border: 0;
            padding: 0;
            font-size: 14px;
            text-transform: uppercase;
            padding-right: 50px;

            &:hover {
              color: $text_red;
            }

            ion-icon {
              font-size: 16px;
              vertical-align: -1px;
            }
          }
        }
      }

    }

    .item-quantity {

      .incdecbtn {
        border: 2px solid #292D32;
        font-size: 12px;
        width: 20px;
        height: 20px;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        line-height: 19px;
        color: $color__secondary;
        background-color: $color__primary;
        margin: 2px;
        border-radius: 6px;
        cursor: pointer;
        padding: 0;

        &.item-count {
          line-height: 17px;
          background-color: #FFF;
          cursor: initial;
          font-size: 13px;
        }

      }

    }

    .item-unit-price, .item-total-price {
      border-left: 1.5px solid #E1E1E1;
    }

  }

  .cart-item-head {

  }

  .cart-total-block {
    text-align: right;

    h3 {
      padding: 50px 30px 50px 0;
    }

    .cta-block {
      .btn:first-of-type {
        margin-left: 15px;
      }

      .btn {
        padding: 10px 48px;
      }
    }
  }
}



/**
Checkout page
 */
.cart-page {
  .shopped-items {

    .cart-total-block .cta-block .btn:first-of-type {
      margin: 0;
    }

    .cart-total-block h3 {
      padding: 50px 0;
      font-size: 18px;
      color: #898989;

      #cart-total {
        font-size: 1.75rem;
        color: #000;
        margin-left: 20px;
      }
    }

    .item-quantity {
      white-space: nowrap;
    }

    tr.cart-item .item-description {

      .item-details .actions {
        position: relative;
        left: 0;
      }

      figure {
        width: 70px;
        height: 100px;
        margin-right: 20px;
      }

      .item-details {
        float: left;
        width: calc(100% - 90px);
      }
    }

    tr.cart-item td {
      padding: 20px 10px;
    }
    tr.cart-item td:first-of-type {
      padding-left: 20px;
    }
  }
}

.shipping-details {
  margin-top: 75px;
  background: #FFFFFF;
  box-shadow: 0px 0px 5px 1px rgb(0 0 0 / 10%);
  padding: 30px;

  .form-group {
    margin: 25px 0;
  }
}

