.main {}
.section-inner-title {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  text-transform: uppercase;
}

@import 'hero-blocks';
@import 'articles';
@import 'books';
@import 'category_articles';
@import 'editors-picks';
@import 'experts';
