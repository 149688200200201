.articles {
  padding: 40px 0;
  position: relative;
  margin-right: 40px;
  width: 60%;

  &:before {
    content: "";
    width: calc(100% + 100%);
    height: 100%;
    background-color: #F5F5F5;
    display: inline-block;
    position: absolute;
    z-index: -1;
    padding-left: 100px;
    margin-left: -100%;
    top: 0;
    bottom: 0;
  }

  @media (max-width: 575.98px) {
    width: 100%;
    padding: 20px;
    background-color: $bg_light_grey;
    margin: 0;

    &:before {
      content: none;
    }
  }

  .articles-left { width: 47%; float:left; padding-right: 20px;}
  .articles-right { width: 53%; float:left; padding: 0 40px 0 20px;}

  .article-card {
    padding: 30px 0;
    border-bottom: 1px solid $border_light_color;

    &:last-of-type {
      padding-bottom: 5px;
      border: 0;
    }

    .article-image {
      margin: 0 0 15px;
    }
    .article-tag {
      text-transform: uppercase;
      font-size: 14px;
      line-height: 18px;
    }
    .article-title {
      font-size: 20px;
      line-height: 26px;
      font-weight: 500;
      padding: 0 0 10px;
    }
    .article-issn {
      text-transform: uppercase;
      font-size: 20px;
      line-height: 24px;
    }
    .article-volume-no {
      text-transform: uppercase;
      font-size: 20px;
      line-height: 24px;
    }
    .article-description {
      font-size: 14px;
      padding: 15px 0 10px;
    }
    .article-author {
      font-size: 12px;
    }
  }
}
