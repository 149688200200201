header {
  position: relative;

  #slide-menu-button {
    font-size: 24px;
    width: 33px;
  }


  .slidemenu {
    position: absolute;
    padding: 20px 0;
    background: #fff;
    width: 100%;
    box-shadow: 0px 4px 8px #ccc;
    top: -260%;
    transition: all .8s;
    z-index: 999999;

    &.show {
      top: 60px;
    }

    ul {

      li {
        display: block;
        padding: 0;

        &.parent-link {
          a {
            font-size: 18px;
            color: $text_red;
            padding: 5px 0 15px;
          }
        }

        a {
          font-size: 14px;
          padding: 5px 0;
        }
      }
    }
  }

  ul > li { display: inline-block; }

  .top-bar {
    padding: 5px 0;
    position: relative;
    z-index: 3;

    .search-icon img { width: 22px; }
    .menu-icon img { width: 24px; }

    ul:not(.User-settings-dropdown) {
      display: flex;
      align-items: center;

      > li {
        padding: 0 10px;
  
        &:first-of-type { padding-left: 0; }
        &:last-of-type { padding-right: 0; }
  
        a {
          font-size: 18px;
  
          &:hover { color: $text_red; }
        }
      }

    } 

    .User-settings-and-notifications {
      display: flex;
      align-items: center;
    }

    .User-settings-icon {
      width: 40px;
      height: 40px;
      overflow: hidden;
      padding: 0;
      border: 1px solid #05050514;
      border-radius: 50%;
      background: #000;
      color: #fed51d;
      font-size: 18px;

      &:after {
        content: none;
      }
      
    }
    

    .User-settings-dropdown {
      padding: 0 0 20px;

      li {
        width: 100%;

        a {
          padding: 20px 20px 0;
          white-space: nowrap;

          &:hover {
            background: #fff;
            text-decoration: none;
          }
        }
      }
    }



    .left > li  a { font-weight: 500; }

    .logo-block {
      margin-top: -12px;

      img {
        width: 100px;
      }
    }
  }

  .navigation {
    padding: 10px 0;
    margin: 0 0 10px 0;
    border-bottom: 1px solid #efefef;
    justify-content: space-between;
    align-items: center;
  }

  .navigation-links {
    // width: 88%;
    // float: left;
    flex: 1;

    li {
      padding: 0 15px;

      &:first-of-type {
        padding-left: 0;
      }

      &:last-of-type {
        padding-right: 0;
      }

      a {
        font-size: 16px;
        line-height: 30px;
        text-transform: uppercase;
        color: #888;

        &.active {
          color: $text_red;
        }
      }
    }
  }

  .social-links {
    float: right;
    width: 12%;
  }



  //filter: invert(90%) sepia(98%) saturate(4320%) hue-rotate(331deg) brightness(97%) contrast(106%);

  &.mobile-header {

    .right {
      li {
        padding: 0;

        .ellipsis-vertical {
          font-size: 24px;
        }
      }
    }
  }
}
