/* pt-serif-regular - latin */
@font-face {
    font-family: 'PT Serif';
    font-style: normal;
    font-weight: 400;
    src: url('../../fonts/Pt_serif/pt-serif-v12-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../fonts/Pt_serif/pt-serif-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../fonts/Pt_serif/pt-serif-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../fonts/Pt_serif/pt-serif-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../../fonts/Pt_serif/pt-serif-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../fonts/Pt_serif/pt-serif-v12-latin-regular.svg#PTSerif') format('svg'); /* Legacy iOS */
  }
  /* pt-serif-700 - latin */
  @font-face {
    font-family: 'PT Serif';
    font-style: normal;
    font-weight: 700;
    src: url('../../fonts/Pt_serif/pt-serif-v12-latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('../../fonts/Pt_serif/pt-serif-v12-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../fonts/Pt_serif/pt-serif-v12-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../fonts/Pt_serif/pt-serif-v12-latin-700.woff') format('woff'), /* Modern Browsers */
         url('../../fonts/Pt_serif/pt-serif-v12-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../fonts/Pt_serif/pt-serif-v12-latin-700.svg#PTSerif') format('svg'); /* Legacy iOS */
  }