.category-article-blocks {}
.categroy-block {
  width: 100%;
  padding: 0 40px 40px;
  border-right: 1px solid $border_light_color;


  &:first-of-type {
    padding-left: 0;
  }
  &:last-of-type {
    padding-right: 0;
    border: 0;
    margin: 0;
  }

  @media (max-width: 575.98px) {
    padding: 0;
    border: 0;
    margin: 0 0 30px;
  }

  .section-inner-title {
    font-size: 24px;
    line-height: 30px;
    @media (max-width: 575.98px) {
      font-size: 22px;
      line-height: 28px
    }
  }

  .category-article {

    &.main {
      width: 100%;
      height: 250px;
      margin: 30px 0;
      @media (max-width: 575.98px) {
        margin: 15px 0;
      }

      .details {
        left: 20px;
        bottom: 20px;
      }
    }
    &.sub-main {
      margin: 0 0 30px;
      @media (max-width: 575.98px) {
        margin: 0 0 15px;
      }

      figure { 
        width: 150px; 
        height: 130px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .details-sub-main {
        padding: 0 20px;
        width: calc(100% - 150px);

        .category-article-title {
          height: 72px;
          overflow: hidden;
        }
      }
    }

    .author {
      font-size: 12px;
      @media (max-width: 575.98px) {
        font-size: 10px;
      }
    }

    .category-article-title {
      margin-bottom: 15px;
      font-size: 18px;
      line-height: 24px;
      font-weight: 500;
    }

    &.listing {
      li {
        padding: 15px 0;
        border-top: 1px solid $border_light_color;
        height: 80px;
        overflow: hidden;
        @media (max-width: 575.98px) {
          padding: 10px 0;
        }

        ion-icon {
          font-size: 16px;
          vertical-align: -2px;
        }
      }

      .category-article-title {
        margin: 0;
      }

    }


    .author {}
  }
}
