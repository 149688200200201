$color__primary: #FED51D;
$color__secondary: #000000;

// Backgrounds
$bg_white: #FFFFFF;
$bg_light_grey: #F5F5F5;
$bg_yellow: $color__primary;
$bg_black: $color__secondary;


// Text colors
$text_white: #FFFFFF;
$text_red: #E1002A;
$text_black: #000000;
$text_primary: $color__primary;
$text_light_black: #111111;
$text_grey: #808080;
$text_light_white: #F5F5F5;

// borders
$border_nav_color: #AFAFAF;
$border_light_color: #CDCDCD;
