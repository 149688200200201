.experts-views {}
.expert-article {
  margin: 20px;
  width: 25%;

  img {
    width: 305px;
    height: 225px;
  }

  &:first-of-type {margin-left: 0;}
  &:last-of-type {margin-right: 0;}
  @media (max-width: 575.98px) {
    margin: 15px 0;
    width: 48%;
  }

  .category-tag {
    padding: 10px 0;
    display: inline-block;
  }
  .expert-article-description {
    font-size: 16px;
    line-height: 24px;
    display: block;
    height: 72px;
    overflow: hidden;
    @media (max-width: 575.98px) {
      font-size: 14px;
      line-height: 20px;
    }

    &:hover { text-decoration: underline; }
  }
  .expert-name {
    padding: 5px 0;
    text-transform: uppercase;
  }

}
